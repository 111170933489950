import { render } from '@testing-library/react';
import React,{useState, useEffect} from 'react';

 import "./Dropdown.css"

export const  DropdownAbout = () =>{


        return(
            <div >           
            <ul className="list">
                <li><a  className="item" href="https://icaidhanbad.org/about" >Meet Us</a>  </li>                  
                <li><a  className="item" href="https://secretariat.icaidhanbad.org/" >Secretariat</a>  </li>       
                <li><a  className="item" href="https://cpe-subcommittee.icaidhanbad.org/">CPE Sub-Committee</a>  </li> 
                <li><a  className="item" href="https://enews-subcommittee.icaidhanbad.org/">CPE Sub-Committee</a>  </li>                
                <li><a  className="item" href="https://gallery.icaidhanbad.org/" >Gallery</a>  </li>
                    
            </ul>
            </div>


    
        );

}