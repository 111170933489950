import React,{Fragment, useContext, useEffect, useState} from 'react';
import firebase from "../Common Components/firebase";
import IdleTimeContainer from "../Common Components/IdleTimeContainer"
import { MDBCol, MDBIcon, MDBContainer, MDBRow} from "mdbreact";
import EmailIcon from '@material-ui/icons/Email';
import PhoneInTalkRoundedIcon from '@material-ui/icons/PhoneInTalkRounded';
import LocationOnRoundedIcon from '@material-ui/icons/LocationOnRounded';
import { IconButton } from '@material-ui/core';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import { Navbar } from '../Navbar/Navbar';
import "../MemberDirectory.css";
import MemberPortalDownload from './MemberPortalDownload';
import { AuthContext } from "../auth/Auth";
import SideMenuBar from '../Navbar/SideMenuBar'
import { LoginNavbar } from '../Navbar/LoginNavbar';
import LoaderUi from '../Common Components/LoaderUi'
import { makeStyles } from '@material-ui/styles'
import { placeholderManImage, toTitleCase } from '../Common Components/CommonFunctions';
import MemberImageDisplayModal from '../Modal/MemberImageDisplayModal';
import Modal from '../Modal/Modal';

const useClasses = makeStyles(theme => ({
  iconContainer: {
      "&:hover $icon": {
          color: 'rgb(97,85,224)',
      }
  },
  icon: {
      color: '#291770',
  },
}))
function StudentDirectory() {
  const classes = useClasses()

  const [schools, setschools] =useState([]);
  const [loading, setloading] = useState([false]);
  const [modalImage, setModalImage] = useState("");
  const [userType, setUserType] = useState("")
  const[searchTerm, setSearchTerm] = useState("");
  const [imageModal, setImageModal] = useState(false)
  const {currentUser} = useContext(AuthContext);
  const uid = currentUser.uid
  const [modalOpen, setModalOpen] = useState(false)

  const ref1 = firebase.firestore().collection('users').doc(uid)
  useEffect(() => {
    ref1
      .get()
      .then((doc) => {
        if (doc.exists) {
          
          setUserType(doc.data().type)
        
               } else {
          // doc.data() will be undefined in this case
        }
      })
      .catch((error) => {});
     
    
  }, []);

let  ref = firebase.firestore().collection("students").orderBy('name');

 


 function getSchools(){
   setloading(true);
   const items=[];
   ref.onSnapshot((querySnapshot)=>{   
     querySnapshot.forEach((doc)=>{
           items.push(doc.data());
     });
     console.log(items)
     setschools(items);
     setloading(false);
     
   })
 }   
 useEffect(()=>{
   getSchools();
 },[]);

 function OpenImageModal(imageSrc){
   setModalImage(imageSrc)
   setImageModal(true)
 }
 if(loading){
   return (<LoaderUi/>)
 }







return (
    <Fragment >
      <IdleTimeContainer/>
      <LoginNavbar />
      <Navbar/>
      <SideMenuBar />
      {modalOpen && <Modal setOpenModal={setModalOpen}/>}
      {imageModal && (<MemberImageDisplayModal imageSrc={modalImage} setOpenModal={setImageModal}/>)}
      <div className="divmargin">
  
           <div style={{paddingTop:"120px",zIndex:0}}></div>
    <MDBContainer  
    >
      <MDBRow>  <MDBCol md="3" >
      </MDBCol>
      
      <MDBCol md="6">  <div className="card  d-flex justify-content-center "
    // style={{
    //   padding: "16px",
    //   margin: "16px",
    //   width: "38rem",
    //   display:"inline"
      
    // }}
      >
      <div className="p-2 col-example text-left">
        <span>    
        <input type="text"  onChange={(event)=>{
      setSearchTerm(event.target.value); }} placeholder="Search By Name..."style={{height:"50px", width:"90%", float:"left", fontSize:"30px",color:"#291770"}}/>
      </span>
 </div></div>
      
      </MDBCol>
      <MDBCol md='1'></MDBCol> 
      <MDBCol md='2'>{userType=="admin"?<MemberPortalDownload />:""}</MDBCol>
      
      </MDBRow>
     
    </MDBContainer>
  
  
  
    

      {schools.filter((val)=>{
        if(searchTerm == "" && val.test==null){  return val}
        else if(val.name.toLowerCase().includes(searchTerm.toLowerCase()) && val.test==null){return val}      
      }).map((school,key)=>(       
 
        <div  key={school.mid} >     
    
        <div  style={{padding:"20px",cursor:"pointer"}} className="d-flex bd-highlight example-parent justify-content-around">
        <div className="p-2  bd-highlight col-example" ></div>

           <div className="p-2  flex-shrink-1 bd-highlight col-example" >
           
          <img src={school.image} onError={(e)=>{e.target.onerror = null; e.target.src=placeholderManImage}} onClick={()=> OpenImageModal(school.image)} width="200px" style={{ width: 150,
              height: 150,
              borderRadius: 100/ 2,
              overflow: "hidden",
              border:"1px solid #291770"
            }} 

            
               />

</div>

<div className="p-2 flex-fill flex-grow-1 bd-highlight col-example" style={{textAlign:"center", border:"2px solid #6256E0"}} onClick={()=> window.location.href=`/student-directory/${school.mid}`}>
 
         <p style={{color:"black", fontSize:"30px" }} > CA&nbsp;{toTitleCase (`${school.name}`)}</p>
         
         <p style={{color:"#291770", fontSize:"30px" }} > {school.mid} </p>
 
        
          </div>
          
          <div className="p-2 flex-shrink bd-highlight col-example modal1" >
            
           <IconButton href={"mailto:"+  school.email} classes={{ root:classes.iconContainer}} ><EmailIcon style={{fontSize:100}} className={classes.icon} /> </IconButton> 
           <IconButton href={"tel:"+  school.phone}  classes={{ root:classes.iconContainer}} > <PhoneInTalkRoundedIcon style={{fontSize:100}} className={classes.icon} /> </IconButton>
           <IconButton href={"https://www.google.com/maps/dir// "+  school.raddress} classes={{ root:classes.iconContainer}} > <LocationOnRoundedIcon style={{fontSize:100}} className={classes.icon} /> </IconButton>
           <IconButton href={"https://wa.me/"+ "+91"+school.whatsapp}  classes={{ root:classes.iconContainer}} ><WhatsAppIcon style={{fontSize:100}} className={classes.icon}></WhatsAppIcon></IconButton>
            
            </div>
        </div></div>
        
      
      
            
      ))}
 
 </div>   
   
    </Fragment>   
  );
}

export default StudentDirectory;
