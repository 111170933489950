import React, { useEffect, useState } from "react";
import WhatsappContext from "./WhatsappContext";
import firebase from "../Common Components/firebase"
import LoaderUi from "../Common Components/LoaderUi";


const WhatsappState = (props) =>{
    const [api, setApi] = useState("")
    const [plus, setPlus] = useState("")
    const [add91, setAdd91] = useState("")
    const [msgParamKey, setMsgParamKey] = useState("")
    const [typeTextInUrl, setTypeTextInUrl] = useState("")
    const [mobileParamKey, setMobileParamKey] = useState("")
    const [loading, setLoading] = useState(true);

    useEffect(()=>{
    
      
            const ref1 = firebase.firestore().collection("website").doc("whatsapp")
        
            ref1.get().then((doc)=>{
              setApi(doc.data().api)
              if(doc.data().add91 != undefined && doc.data().add91.toLowerCase() == "yes"){
                setAdd91("91")
              }
              if(doc.data().plus != undefined && doc.data().plus.toLowerCase() == "yes"){
                setPlus("+")
              }
             
              if(doc.data().includetype != undefined && doc.data().includetype.toLowerCase()== "yes"){
                let textKey = doc.data().typetextkey
                let textvalue = doc.data().typetextvalue
                
                let y = `&${textKey}=${textvalue}`
               
                setTypeTextInUrl(y)
              }
              setMobileParamKey(doc.data().mobileparamkey)
              setMsgParamKey(doc.data().msgparamkey)
        
            })
            setLoading(false)
          },[])
        
      
          if(loading)
            return(<LoaderUi/>)
    return(
        <WhatsappContext.Provider value={{add91, plus, mobileParamKey, msgParamKey,typeTextInUrl,api}}>
            {props.children}
        </WhatsappContext.Provider>
    )
}

export default WhatsappState;