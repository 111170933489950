import React, { useState, useEffect, useContext } from "react";
import { useLocation } from "react-router-dom";
import IdleTimeContainer from "../Common Components/IdleTimeContainer";
import firebase from "../Common Components/firebase.js";
import { Fragment } from "react";
import { Navbar } from "../Navbar/Navbar";
import "../card.css";
import { AuthContext } from "../auth/Auth";
import PhoneInTalkRoundedIcon from "@material-ui/icons/PhoneInTalkRounded";
import LocationOnRoundedIcon from "@material-ui/icons/LocationOnRounded";
import { IconButton } from "@material-ui/core";
import EmailIcon from "@material-ui/icons/Email";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import TitleCase from 'react-title-case';
import LoaderUi from "../Common Components/LoaderUi";
import SideMenuBar from '../Navbar/SideMenuBar'
import { LoginNavbar } from '../Navbar/LoginNavbar';
import BirthdayPanel from "../Panels/BirthdayPanel";
import Modal from "../Modal/Modal"
import { placeholderManImage, toTitleCase } from "../Common Components/CommonFunctions";
import StudentBirthdayPanel from "../Panels/StudentBirthdayPanel";
import { Subject } from "@material-ui/icons";

export default function Memberdetails() {
  const state = useLocation();
  let path = state.pathname;
  let arr = path.split("/");
  let mid = arr[2];

  const [schools, setschools1] = useState([]);
  const [loading, setloading1] = useState([false]);
  const [currentUserType,setCurrentUserType] = useState("")
  const [dob, setDob] = useState("")
  const [birthDate, setBirthDate] = useState();
  const [birthMonth, setBirthMonth] = useState();
  const [birthYear, setBirthYear] = useState();
  const [isAdmin, setIsAdmin] = useState(false)
  const [uid, setUid] = useState("")
  const [modalOpen, setModalOpen] = useState(false)

  const { currentUser } = useContext(AuthContext);

  const currentUserId = currentUser.uid;



  const ref1 = firebase.firestore().collection("users").doc(currentUserId);


  useEffect(() => {
    ref1
      .get()
      .then((doc) => {
        if (doc.exists) {
         
          setCurrentUserType(doc.data().type)
       
       }
       
       else {
        }
      })
      .catch((error) => {});
     
    
  }, []);


  const ref = firebase.firestore().collection("users");
  
  function getSchools() {
    
    setloading1(true);

    ref.onSnapshot((querySnapshot) => {
      const items = [];
      

      querySnapshot.forEach((doc) => {
      
        if (doc.data().mid === mid){ 
          setUid(doc.id)
          items.push(doc.data());
          setDob(doc.data().dob);
          // setDoa(doc.data().doanniversary);

        }
      });
      setschools1(items);
      setloading1(false);
    });

    
  }


  useEffect(()=>{
    const dobarr = dob.split("/")
   
    const month =["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sept","Oct","Nov","Dec"]

    setBirthDate(dobarr[0])
    setBirthYear(dobarr[2])
    setBirthMonth(month[dobarr[1]-1])

    
  },[dob])



  useEffect(()=>{

    if(currentUserType == "admin")
      setIsAdmin(true)


  },[currentUserType])

  useEffect(() => {
    getSchools();
   
  }, []);


  if (loading) {
    return (<LoaderUi/>);
  }

  return (
    <Fragment>
      <IdleTimeContainer/>
      <LoginNavbar />
      <Navbar/>
      <SideMenuBar />
      {modalOpen && <Modal setOpenModal={setModalOpen}/>  }
      <BirthdayPanel/>
      <StudentBirthdayPanel/>
      <div style={{ paddingTop: "120px" }}></div>
      <div
        style={{ paddingTop: "30px", paddingBottom: "30px" }}
        className="justify-content-center "
      >
        <div className="d-flex bd-highlight example-parent">
          <div className="p-2 flex-fill bd-highlight col-example"></div>

          {schools.map((school) => (
            <div key={school.userid}>
              <div className="card2 ">
                <div className="upper-container">
                  <div className="image-container1">
                    <img
                      src={
                        school.image
                      }
                      onError={(e) => {
                        e.target.src = placeholderManImage
                        e.target.onError = null;
                      }}
                  
                      width="200px"
                  
                    ></img>
                  </div>
                </div>
                <div className="lower-container">
                  <h3> CA&nbsp;{toTitleCase(school.name)}</h3>
                  <h4>{school.mid}</h4>
                  <h3 style={{ color: "yellowgreen" }}>Personal Details</h3>
                  <p>Date of Birth : {" "}{birthDate}-{birthMonth}  {isAdmin? -birthYear:""} </p>
                  <p>Blood Group: {school.bloodgroup}</p>
                  <h3 style={{ color: "yellowgreen" }}>Contact Details</h3>
                  <p> Address: {school.raddress}</p>
                  <p>Email: {school.email}</p>
                  <p>Phone: {school.phone}</p>
                  <p>Whatsapp: {school.whatsapp}</p>
                  {(school.faddress !="" || school.firmname!="") && (<>
                  <h3 style={{ color: "yellowgreen" }}>Firm  Details</h3>
                  {school.firmname !="" && (<p>Firm Name: {school.firmname.toUpperCase()}</p>)}
                  {school.faddress !="" && (<p>Firm Address: {school.faddress}</p>)}</>
                  )}
                  <h3 style={{ color: "yellowgreen" }}>Operations</h3>

                  <IconButton href={"mailto:" + school.email}>
                    <EmailIcon style={{ fontSize: 50 }} />{" "}
                  </IconButton>
                  <IconButton href={"tel:" + school.phone}>
                    {" "}
                    <PhoneInTalkRoundedIcon style={{ fontSize: 50 }} />{" "}
                  </IconButton>
                  <IconButton
                    href={"https://www.google.com/maps/dir// " + school.raddress}
                  >
                    {" "}
                    <LocationOnRoundedIcon style={{ fontSize: 50 }} />{" "}
                  </IconButton>
                  <IconButton href={"https://wa.me/+91" + school.whatsapp}>
                    <WhatsAppIcon style={{ fontSize: 50 }}></WhatsAppIcon>
                  </IconButton>
                </div>
              </div>
            </div>
          ))}
          <div className="p-2 flex-fill bd-highlight col-example"></div>
        </div>
      </div>
    </Fragment>
  );
}
