import React,{useState, useEffect} from 'react';
import { ButtonSignOut } from '../Common Components/ButtonSignOut';
import { logo } from '../Common Components/CommonFunctions';

import "../Navbar.css"
import { SidebarData}  from "./SideMenuItems";


export const  Navbar = () =>{

        const [clicked, setClicked] = useState(false);

     

        return(
            <nav className="NavbarItems" ><div className="navbar-logo"><img src={logo} width={200} ></img></div> 
            <div className="menu-icon" onClick={()=>setClicked(!clicked)}>
                <i className={clicked? "fas fa-times":"fas fa-bars"}></i> </div>
            <ul className={clicked? "nav-menu active" : "nav-menu"}>
                {SidebarData.map((item, index) => {
                    return(<li key={index}><a className="nav-links" href={item.url}   >{item.title}</a>  </li>   )
                                })}
               {/* <li>
               <a className="nav-links" href="https://vipca.co.in"> Home</a>   
                <a className="nav-links" href="/member-directory"> Member Portal</a> 
               <a className="nav-links" href="/"> My Profile</a> 
               <a className="nav-links" href="/update-profile">Update Profile</a>
                                 */}
              
             <li>  <ButtonSignOut style={{fontSize:"1.2rem", paddingBottom:"11px"}}>Sign Out</ButtonSignOut>  </li> 
            
            </ul>
             {/* <ButtonSignOut className="ButtonSignOut nav-menu">Sign Out</ButtonSignOut> 
              */}
         
            </nav>
        );

}