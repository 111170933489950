import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "../auth/Auth";
import { useHistory } from "react-router";
import "./UpdateModal.css";
import firebase from "../Common Components/firebase";
import ModalLoaderUi from "../Common Components/ModalLoaderUi";
import { toTitleCase } from "../Common Components/CommonFunctions";
import Select from "react-select";

function AddSecreteriatModal({ setOpenModal, users, secreteriatid, secArray, availablePosts, currentuserid}) {

 const [selectedPost, setSelectedPost] = useState('')
 const [loading, setLoading] = useState(false)
 const [buttonDisabled, setButtonDisabled] = useState(false)
 const [selectedUserId, setSelectedUserId] = useState('')
 const [committeeDisplay,setCommitteeDisplay] = useState('')
 const [displayOrderJSON, setDisplayOrderJSON] = useState({})

 useEffect(()=> {
     GetCommitteeYearDisplay()
     GetDisplayOrder()
},[])

 function AddSecreteriat(){
     setLoading(true)
     const ref = firebase.firestore().collection('secreteriat').doc(secreteriatid)
     ref.get().then((doc)=>{
        if(!doc.exists)
         ref.set({[`${selectedPost}`]: 'r'})
        
        else
            ref.update({[`${selectedPost}`]:"r"})
        });
     const ref1 = ref.collection('posts').doc(selectedPost)
     ref1.set({
         postid : selectedPost,
         userid : selectedUserId['value'],
         adminid : currentuserid,
         postname : GetPostName(selectedPost),
         displayorder: displayOrderJSON[selectedPost],
         secreteriatid: secreteriatid
         
     }).then(()=>{
         window.location.reload()
         window.alert("New Secreteriat Added !!")
         setOpenModal(false)
      
     })
 }
 
    function GetCommitteeYearDisplay(){
        
        for(let i=0; i< secArray.length; i++){
            if(secArray[i]['id'] == secreteriatid){
                setCommitteeDisplay(secArray[i]['value'])
                return
            }
        }
    }
 function GetPostName(postid){
     for(let i=0; i< availablePosts.length; i++){
         if(availablePosts[i]['id'] == postid){
            return availablePosts[i]['displayname']
         }

     }
 }
 function GetDisplayOrder(){

     firebase.firestore().collection('secreteriat').doc('displayorder').get().then((doc)=>{
         setDisplayOrderJSON(doc.data())
     })
 }


  return (
    <div className="modalBackground1">
      <div className="modalContainer1" style={{height:"auto", maxHeight:"90vh", width:"auto", overflowY:"scroll"}}>
        <div className="titleCloseBtn">
          <button
            onClick={() => 
                setOpenModal(false)
            }
          >
            X
          </button>
        </div>
        <div className="title">
  <center> <h2 style={{color:"red"}}>  !! Add New Secreteriat !! </h2> </center>

        </div>
        {loading ? <ModalLoaderUi/> :<>
        <div className="body">{availablePosts.length !=0 ? <>
        <div className="inputBx1">
                 <span>Committee Year</span><span style={{color:"red"}}>*</span><br/>
                <input type="text" value={committeeDisplay} disabled={true}/>
               </div>
        <div className="inputBx1">
                 <span>Position</span><span style={{color:"red"}}>*</span><br/>
                 <select value={selectedPost}  onChange={(e)=> setSelectedPost(e.target.value) }>         
                 <option value="">Choose</option>
                {availablePosts.map((val, key)=> (<option value={val.id} key={key}>{toTitleCase(val.displayname)}</option>))}
                   </select>  
               </div>

               <div className="inputBx1">
                 <span>Choose Member</span><span style={{color:"red"}}>*</span><br/>
               
                <Select options={users} placeholder="Select User... Type to Search" value={selectedUserId} onChange={(e)=> setSelectedUserId(e)} />
               </div>
               </>
            :<>
                <h1>No More Positions available for selected Year !</h1>
            </>}
        </div>
        <div className="footer">
       <button
            onClick={() => {
             setOpenModal(false)
             
            }}
        
          >
          Cancel
          </button>
          {availablePosts.length !=0 ?
     
          <button
            onClick={() => {
                AddSecreteriat()
            }}
             disabled={buttonDisabled}
          >
          Add Secreteriat
          </button>
          :""}
        </div>
        </>}
      </div>
   
    </div>
  );
}

export default AddSecreteriatModal;