import React, { Fragment, useEffect, useState, useContext } from "react";
import "../Registration.css"
import RegistrationPart1 from "./RegistrationPart1";
import { useHistory } from "react-router-dom";
import Carousel from "react-material-ui-carousel";
import { LoginNavbar } from "../Navbar/LoginNavbar";
import { LoginPageNavbar } from "../Navbar/LoginPageNavbar";
export default function Registration(props) {
  const[uid, setUid] = useState()
  const[membershipId, setMid] = useState(props.membershipId)
  const [confirmPassword, setConfirmPassword] = useState("")
  const [email, setEmail] = useState("");
  const[showConfirmPassword, setShowConfirmPassword] = useState(false) 
  const [password, setPassword] = useState("");
  const [whatsapp, setwhatsapp] = useState(props.whatsapp);
   const [buttonDisabled, setButtonDisabled] = useState(true)
  const [showPassword, setShowPassword] = useState(false)
  const [showEmailError, setShowEmailError] = useState(false)
  const [emailErrorMessage, setEmailErrorMessage] = useState("Please Enter a valid Email Address")
  const [passwordErrorMessage, setPasswordErrorMessage] = useState("Password doesn't match")
  const [showPasswordErrorMessage, setShowPasswordErrorMessage] = useState(false)
  const [showNextPart, setShowNextPart] = useState(false)
  const history = useHistory()
 
  useEffect(()=>{
    if(email.trim() != "" && password.trim() !="" && confirmPassword.trim() !="" && !showPasswordErrorMessage && !showEmailError){

      setButtonDisabled(false)
    }
    else
      setButtonDisabled(true)

  },[password, confirmPassword, showPasswordErrorMessage, email])
    

  useEffect(()=>{
    if(password.trim() != "" && confirmPassword.trim() !="")
    {
      if(password != confirmPassword)
        setShowPasswordErrorMessage(true)
      else
        setShowPasswordErrorMessage(false)
    }
    else{
      setShowPasswordErrorMessage(false)
    }
  },[password, confirmPassword])
  const ValidateEmail = (value) =>{
    if (value !== "undefined") {

      var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
    
      if (!pattern.test(value)) {
    
        setShowEmailError(true)
        
    
       
    
      } else {
        setShowEmailError(false)
      }
    
    }
  }

  function nextPart(){
   
    setShowNextPart(true)
  }

  return (
    <Fragment>
      <LoginNavbar/>  
       <LoginPageNavbar/>
      {!showNextPart ? 
            <section>
         <div className="imgBx">
         <Carousel className="carousel1" indicators={false} autoPlay={true}>
            {Object.values(props.images).map((doc)=>(
            <> <img src ={doc} /></> 
            ))}
          </Carousel>
         </div>
         <div className="contentBx">
           <div className="formBx">
             <h2>Registration</h2>
             
               <div className="inputBx">
                 <span>Email</span>
                 <input type="text" autoComplete="off"value={email} onChange={(e)=> {setEmail(e.target.value);ValidateEmail(e.target.value)}} /> 
                 {showEmailError ?<p style={{color:"#291770"}}> {emailErrorMessage} </p> : ""}
               </div>
               <div className="inputBx">
                 <span>Password</span>
                 <input type={!showPassword ?"password":"text"} autoComplete="off" value={password}  onChange={(e)=> setPassword(e.target.value)}/> 
               </div>
               <div className="remember">
                 <label>  <input type="checkbox" onChange={()=> setShowPassword(!showPassword)}  /> Show Password</label>
               </div>

               <div className="inputBx">
                 <span>Confirm Password</span>
                 <input type={!showConfirmPassword ?"password":"text"}value={confirmPassword} autoComplete="off" onChange={(e)=> setConfirmPassword(e.target.value)}/> 
               </div>
               <div className="remember">
                 <label>  <input type="checkbox" name="" onChange={()=> setShowConfirmPassword(!showConfirmPassword)}  /> Show Password</label>
                 {showPasswordErrorMessage ?<p style={{color:"#291770"}}> {passwordErrorMessage} </p> : ""}
               </div>


               <div className="inputBx">             
                 <input type="button" value="Next"  disabled={buttonDisabled} onClick={nextPart}/> 
               </div>


            
           </div>
         </div>
       </section>
:<RegistrationPart1 whatsapp={whatsapp} membershipId ={membershipId} email={email} password={password} images={props.images}/>}

         
    </Fragment>
  );
}

