import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "../auth/Auth";
import { useHistory } from "react-router";
import "./UpdateModal.css";
import firebase from "../Common Components/firebase";
import LoaderUi from "../Common Components/LoaderUi";
import ModalLoaderUi from "../Common Components/ModalLoaderUi";
import {UpdateSms} from "../Common Components/SmsCommunication";
function UpdateEmailModal({ setOpenModal}) {
  const history = useHistory()
  const [email,setEmail] = useState("")
  const [fName, setFname] = useState("")
  const [lName, setLname] = useState("")
  const [membershipId, setMembershipId]= useState("")
  const [mobile, setMobile] = useState("")
  const [newEmail, setNewEmail] = useState("")
  const [showEmailError, setShowEmailError] = useState(false)
  const [errorMessage1, setErrorMessage] =  useState("Please Enter a valid Email")
  const [password, setPassword] = useState("")
    const [loading, setLoading] = useState(false)
  const {currentUser} = useContext(AuthContext)
  const currentUserId = currentUser.uid;
  const [buttonDisabled, setButtonDisabled] = useState(true)
  const ref = firebase.firestore().collection("users").doc(currentUserId);
 
  useEffect(() => {
    ref
      .get()
      .then((doc) => {
        if (doc.exists) {
         
          setEmail(doc.data().email)
         
          setMobile(doc.data().phone)
          setMembershipId(doc.data().mid)
       }
       
       else {
        }
      })
      .catch((error) => {});

    
  }, []);
  function toTitleCase(str) {
    if(str!="" && typeof(str)=="string"){
    return str.replace(
      /\w\S*/g,
      function(txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      }
    );} else return str
  }
  const ValidateEmail = (value) =>{
    if (value != undefined && value != "" ) {

      var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
    
      if (!pattern.test(value)) {
    
        setShowEmailError(true)
        
    
       
    
      } else {
        setShowEmailError(false)
      }
    
    }
  }

  function ChangeEmail(){
      setLoading(true)
    const emailCred = firebase.auth.EmailAuthProvider.credential(
        firebase.auth().currentUser.email,
        password
      );

      firebase
      .auth()
      .currentUser.reauthenticateWithCredential(emailCred)
      .then(() => {
        const db = firebase.firestore();
        const users = db.collection("users").doc(currentUserId);
     
     
      firebase.auth().currentUser.updateEmail(newEmail).then(()=>{
        users.get().then((doc) => {
          if (doc.exists) {
            users
              .update({
                email: newEmail,
              })
              .then(function () {

                //   window.alert("Value successfully written!");
              })
              .catch(function (error) {
                window.alert(error.message);
                setOpenModal(false)
              });
          }
        });
    
        // UpdateSms("ss", "11",12221 )
        // SendUpdateEmail()
       setLoading(false)
        window.alert("Your Email has been updated Successfully ! Kindly use new Email for next login" );
        setOpenModal(false)
    
      }).catch((error) => {
        var errorCode = error.code;
        var errorMessage = error.message;
        window.alert(errorMessage);
        setOpenModal(false)
      });

      
      })
      .catch((error) => {
        var errorCode = error.code;
        var errorMessage = error.message;
        window.alert(errorMessage);
        setOpenModal(false)
      });
  }

  return (
    <div className="modalBackground1">
      <div className="modalContainer1" style={{overflow:"scroll", height:"auto", maxHeight:"90vh"}}>
        <div className="titleCloseBtn">
          <button
            onClick={() => 
                setOpenModal(false)
            }
          >
            X
          </button>
        </div>
        <div className="title">
  <center> <h2 style={{color:"red"}}>  !! Update Email !! </h2> </center>

        </div>
        {loading? <ModalLoaderUi/>: <>
        <div className="body">
        <div className="inputBx1">
                 <span>Current Email </span>
                 <input type="text"autoComplete="chrome-off" value={email} disabled={true}/> 
               </div>
               <div className="inputBx1">
                 <span>New Email</span>
                 <input type="text"autoComplete="chrome-off" value={newEmail} onChange={(e)=>{  ValidateEmail(e.target.value) ;setNewEmail(e.target.value)} }/> 
                 {showEmailError ? <p>{errorMessage1}</p>:""}
               </div>
        <div className="inputBx1">
                 <span>Confirm Password</span>
                 <input type="text"autoComplete="chrome-off" value={password} onChange={(e)=>setPassword(e.target.value)} /> 
               </div>

        </div>
        <div className="footer">
        <button
            onClick={() => {
             setOpenModal(false)
             
            }}
        
          >
          Cancel
          </button>
      
     
          <button
            onClick={() => {
             ChangeEmail()
             
            }}
            // disabled={buttonDisabled}
          >
          Update Email
          </button>
      
        </div>
        </>} 
      </div>
     
    </div>
  );
}

export default UpdateEmailModal;