import React,{useState, useEffect} from 'react';
import "../LoginNavbar.css"
import { DropdownAbout } from '../DropdownMenus/DropdownAbout';
import { DropdownExplore } from '../DropdownMenus/DropdownExplore';
import { DropdownServices } from '../DropdownMenus/DropdownServices';

export const  LoginNavbar = () =>{

        const [clicked, setClicked] = useState(false);
        const [showExplore, setShowExplore] = useState(false)
        const [showAbout, setShowAbout] = useState(false)
        const [showServices, setShowServices] = useState(false)

        const onMouseEnterAbout = () => {
           
              setShowAbout(true);
            
          };
        
          const onMouseLeaveAbout = () => {
           
              setShowAbout(false);
            
          };

        const onMouseEnter = () => {
           
              setShowExplore(true);
            
          };
        
          const onMouseLeave = () => {
           
              setShowExplore(false);
            
          };

        const onMouseEnterServices = () => {
           
              setShowServices(true);
            
          };
        
          const onMouseLeaveServices = () => {
           
              setShowServices(false);
            
          };


     

        return(
            <nav className="NavbarItems1" style={{paddingTop:"20px", height:"80px", zIndex:"100"}}> 
           <div className="menu-icon1" onClick={()=>setClicked(!clicked)}>
                <i style={{cursor:"pointer"}}className={clicked? "fas fa-times":"fas fa-bars"}></i> </div>
            <ul className={clicked? "nav-menu1 active" : "nav-menu1"}>
                {/* {MenuItems.map((item, index) => {
                    return(<li key={index}><a className={item.title!='Services'?"nav-links1":"nav-links-portal1"} href={item.url}>{item.title.toUpperCase()}</a></li> )
                })} */}
                 <li><a className="nav-links1"  href="https://mymmuzaffarpur.com/" >HOME</a>  </li>                 
                <li  onMouseEnter={onMouseEnterAbout} onMouseLeave={onMouseLeaveAbout}><a className="nav-links1" href="#">ABOUT US</a> {showAbout? <DropdownAbout/>:""} </li>      
                <li onMouseEnter={onMouseEnterServices} onMouseLeave={onMouseLeaveServices}><a className="nav-links-portal1" href="#" >SERVICES</a> {showServices? <DropdownServices/>:""}  </li>                 
                <li><a className="nav-links1"  href="https://mymmuzaffarpur.com/contact/" >CONTACT US</a>  </li>                  
                <li onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}><a className="nav-links1" href="#" >EXPLORE</a>  {showExplore?<DropdownExplore/>:""}   </li>                 
                       
            </ul>
         
            </nav>
        );

}