import React,{useState, useEffect, useContext} from 'react';
import Carousel from 'react-material-ui-carousel'
import firebase from '../Common Components/firebase'
import { Paper, Button } from '@material-ui/core'
import LoaderUi from '../Common Components/LoaderUi'
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import { Navbar } from "../Navbar/Navbar";
import Grid from "@material-ui/core/Grid";
import { useHistory } from "react-router";
import {LoginNavbar} from '../Navbar/LoginNavbar'
import SideMenuBar from "../Navbar/SideMenuBar";
import { AuthContext } from '../auth/Auth';
import IdleTimeContainer from "../Common Components/IdleTimeContainer";
import DeleteIcon from '@material-ui/icons/Delete';
import Typography from '@material-ui/core/Typography';
import NoticeUploadModal from '../Modal/NoticeUploadModal';
import "./Notice.css"
import { EditAttributes, EditOutlined } from '@material-ui/icons';
import NoticeEditModal from '../Modal/NoticeEditModal';
import { toTitleCase } from '../Common Components/CommonFunctions';
const useStyles = makeStyles({
  root: {
    maxWidth: 600,
    maxHeight:"auto",
 
    minHeight:200,
    minWidth:500
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 24,
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  pos: {
    marginBottom: 12,
  },
});

export default function Notice()
{
    const [items, setItems] = useState([])
    const [loading, setloading] = useState(false)
    const [currentUserType, setCurrentUserType] = useState("")
    const [noticeModalOpen, setNoticeModalOpen] = useState(false)
    const [editNoticeModalOpen, setEditNoticeModalOpen] = useState(false)
    const [yearArray, setYearArray] = useState([])
    const [selectedYear, setSelectedYear] = useState("")
    const [editNoticeId, setEditNoticeId] = useState("")
    const [displayItems, setDisplayItems] = useState([])
    const [publisher, setPublisher] = useState("")
    function EditNotice(id){
      setEditNoticeId(id)
      setEditNoticeModalOpen(true)
    }
    function setYearArrayFn(){
      const date = (new Date()).getFullYear()
      setSelectedYear(date.toString())
      let temp=[]
      for(let i=0; i<10; i++){
        temp.push((date-i).toString())
      }
      setYearArray(temp)
    }
    useEffect(()=>{
        getSchools()
        setYearArrayFn()
       
    },[])
    const classes = useStyles();
    const history = useHistory()
  
let  ref = firebase.firestore().collection("notice").orderBy('timestring','desc');
    function getSchools(){

        setloading(true);
        const temp=[];
        ref.onSnapshot((querySnapshot)=>{
        
          querySnapshot.forEach((doc)=>{
             temp.push(doc.data())
          });
          setItems(temp);
          setloading(false);
        })
      }  
      const { currentUser } = useContext(AuthContext);

      const currentUserId = firebase.auth().currentUser.uid;
    
      const ref1 = firebase.firestore().collection("users").doc(currentUserId);
    
    
      useEffect(() => {
        ref1
          .get()
          .then((doc) => {
            if (doc.exists) {
             
              setCurrentUserType(doc.data().type)
              setPublisher(doc.data().name)
           
           }
           
           else {
            }
          })
          .catch((error) => {});
         
        
      }, []);


      function Delete(id){
        firebase.firestore().collection("notice").doc(id).delete().then(() => {     
          window.alert("Notice Deleted !") 
          window.location.reload()
      }).catch((error) => {
          console.error("Error removing document: ", error);
      });
      
      }
      
      
       function DeleteConfirm(id) {
        var x;
        if (window.confirm("Are you sure ??\nThis can't be undone !!") == true) {
           Delete(id)
        } else {
            x = "Cancel!";
        }
       
      }
      useEffect(()=>{
        if(selectedYear !="" && items.length !=0){
          setDisplayItems([])
          let temp=[]
          for(let i=0; i<items.length; i++){
            if(items[i]['year'] == selectedYear){
              temp.push(items[i])
            }
            setDisplayItems(temp)
          }
        }

      },[items,selectedYear])
    




      if(loading)
        return(<LoaderUi/>)
    return (
        <>
        <IdleTimeContainer />
      <LoginNavbar />
      <SideMenuBar />
      <Navbar />
      {noticeModalOpen && (<NoticeUploadModal setOpenModal={setNoticeModalOpen} publisher={publisher} userid={currentUserId}/>)}
      {editNoticeModalOpen && (<NoticeEditModal noticeId={editNoticeId} setOpenModal={setEditNoticeModalOpen}/>)}
     <div style={{paddingTop:"120px"}}></div>
    
{currentUserType == "admin" ? 
      <div className="d-flex justify-content-end"  >
      <div className="d-flex p-2 col-example" >
      <button onClick={()=>setNoticeModalOpen(true)} className="btn btn-primary btn-block" style={{background:"#291770", fontSize:"20px", marginBottom:"15px"}}>Upload Notice</button><br/><br/>
    </div></div>: ""} 
    <span  style={{marginLeft:"400px"}}>Select Year</span> <br/>
    <select style={{marginLeft:"400px", width:"20%", borderRadius:"30px", fontSize:"16px", padding:"10px"}} value={selectedYear} onChange={(e)=> setSelectedYear(e.target.value)}> 
    {yearArray.map((doc,key)=>(<option value={doc}>{doc}</option>))}
    </select>
    {displayItems.length == 0 ? <h4 style={{marginLeft:"400px", color:"#291770", marginTop:"50px"}}>No Notices are There !!</h4> :<>
{displayItems.map((doc)=>(<ul class="pinboards" style={{marginLeft:"400px"}}>
<li>
<div>
<span style={{float:"right"}}> <DeleteIcon style={{ fontSize:"20px", cursor:"pointer"}} onClick={()=> DeleteConfirm(doc.id)}/> <EditOutlined style={{ fontSize:"20px", cursor:"pointer"}} onClick={()=>EditNotice(doc.id)}/></span> <br/>
<span style={{width:"auto", fontSize:"12px", float:"right"}}>By:- {toTitleCase(doc.publisher)}</span>
<small> {doc.time}</small>
<h4>{doc.title}</h4>
{doc.text}<br/> <br/>
{doc.url !="" && (<a href={doc.url} target="_blank"><i class="fa fa-external-link ">Click to view File</i></a>)}

</div>
</li></ul>))}
</>
}   
{/* <li>
<div>
<small>February 3, 2017</small>
<h4>Top 10 Trending Javascript & jQuery Plugins in 2017</h4>
I would like to share all the trending javascript and jQuery plugins of the year 2017. I personally used all the below plugins in my web development projects.
<br/> <br/>
<a href="http://w3lessons.info" target="_blank"><i class="fa fa-external-link ">Click to View File</i></a>
</div>
</li> */}

        </>
    )
}

