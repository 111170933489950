import React, {
    Fragment,

  } from "react";
  
  import "./SideMenuBar.css"
  import { useLocation } from "react-router-dom";
import { SidebarData}  from "./SideMenuItems";
import ExitToAppIcon from '@material-ui/icons/ExitToApp'

import { ButtonSignOut } from '../Common Components/ButtonSignOut';
import { logo } from "../Common Components/CommonFunctions";
import { AdminSidebarData } from "./AdminMenuItems";

  
  function Sidebar() {
    const state = useLocation()
    let path = state.pathname;
    let arr = path.split("/");
    let mid = arr[1];
     function checkPath(id){
       let x= id.split("/")
  
       return x[1]
     }
    return (
      <Fragment>
       <div className="Sidebar" style={{float:"left", position:"fixed", zIndex:"300"}}>
            <div className="SidebarList ">
            <div className="d-flex justify-content-center"  >
        <div className="d-flex p-2 col-example" >
        <img  src= {logo}width="200px" style={{margin:"15px"}}/>
        
          </div></div>
             <hr/>
               {SidebarData.map((val, key)=>{
                    return(
                        <li key={key} className="row" onClick={()=>{window.location.pathname= val.url}}>
                         
                            <div style={{cursor:'pointer'}} className={checkPath(val.url)==mid?"selected":""}>{val.title}</div>                            
                        </li>

                        
                    )
               }) }
            {AdminSidebarData.map((val, key)=>{
                    return(
                        <li key={key} className="row" onClick={()=>{window.location.pathname= val.url}}>
                         
                            <div style={{cursor:'pointer'}} className={checkPath(val.url)==mid?"selected":""}>{val.title}</div>                            
                        </li>

                        
                    )
               }) }
            
             <li className="row">  <ButtonSignOut style={{fontSize:"20px", color:"#291770", paddingBottom:"11px"}}><ExitToAppIcon/></ButtonSignOut> </li>
            </div>

       </div>

      </Fragment>
    );
  }
  
  export default Sidebar;
  