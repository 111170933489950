import React, { Fragment, useEffect, useState, useContext } from "react";
import Loader from "react-loader-spinner";
import { logo } from "./CommonFunctions";

 export default function LoaderUi(){

    return(<>
    <div style={{paddingTop:"200px"}}></div>
   <div className="d-flex justify-content-center"  >
        <div className="d-flex p-2 col-example" >
        <img  src= {logo} width="200px" style={{margin:"20px"}}/>
        
          </div></div>
          <div className="d-flex justify-content-center"  >
        <div className="d-flex p-2 col-example" >
          

        <h1> Processing Your Request</h1><br/>
   
          </div></div>


          <div className="d-flex justify-content-center"  >
        <div className="d-flex p-2 col-example" >
          
        <Loader
    type="ThreeDots"
    color="#291770"
    height={70}
    width={200}
    
    />
          </div></div>

         
    </>
    )
}
