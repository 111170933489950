import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "../auth/Auth";
import { useHistory } from "react-router";
import "./UpdateModal.css";
import firebase from "../Common Components/firebase";
import LoaderUi from "../Common Components/LoaderUi";
import ModalLoaderUi from "../Common Components/ModalLoaderUi";
import {UpdateSms} from "../Common Components/SmsCommunication";
import { set } from "date-fns";
function UpdatePasswordModal({ setOpenModal}) {
  const history = useHistory()
  const [email,setEmail] = useState("")
  const [name, setName] = useState("")
  const [membershipId, setMembershipId]= useState("")
  const [mobile, setMobile] = useState("")
  const [whatsapp, setWhatsapp] = useState("")
  const [newPassword, setNewPassword] = useState("")
  const [confirmNewPassword, setConfirmNewPassword] = useState("")
  const [currentPassword, setCurrentPassword] = useState("")
    const [loading, setLoading] = useState(false)
  const {currentUser} = useContext(AuthContext)
  const currentUserId = currentUser.uid;
  const [buttonDisabled, setButtonDisabled] = useState(true)
  const ref = firebase.firestore().collection("users").doc(currentUserId);
  const [showPassword, setShowPassword] = useState(false)
  const [showNewPassword, setShowNewPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)
  const [showPasswordErrorMessage, setShowPasswordErrorMessage] = useState(false)
  const [passwordErrorMessage, setPasswordErrorMessage] = useState("Passwords doesn't Match !!")
  useEffect(() => {
    ref
      .get()
      .then((doc) => {
        if (doc.exists) {
          setEmail(doc.data().email)
          setName(doc.data().name)
          setMobile(doc.data().phone)
          setMembershipId(doc.data().mid)
          setWhatsapp(doc.data().whatsapp)
       }
       
       else {
        }
      })
      .catch((error) => {});

    
  }, []);
  
  useEffect(()=>{
    if(newPassword.trim()!= "" && confirmNewPassword.trim() !=""){
        if(newPassword == confirmNewPassword){
            setButtonDisabled(false)
            setShowPasswordErrorMessage(false)
        }else{
            setButtonDisabled(true)
            setShowPasswordErrorMessage(true)
        }
    }else{
        setButtonDisabled(true)
       
    }
  },[newPassword, confirmNewPassword])
  
  function ChangePassword(){
      setLoading(true)
      const emailCred  = firebase.auth.EmailAuthProvider.credential(
        firebase.auth().currentUser.email, currentPassword);
          firebase.auth().currentUser.reauthenticateWithCredential(emailCred)
        .then(() => {
          // User successfully reauthenticated.
  
          const db = firebase.firestore();
          
        
         firebase.auth().currentUser.updatePassword(newPassword).then(()=>{
          db.collection("users")
          .doc(currentUserId)
          .update({
            password: newPassword,
          })
          .then(function () {
  
          })
          .catch(function (error) {
            window.alert( error.message);
          });
         UpdateSms(name, mobile)
         
          window.alert("Your Password has been updated Successfully !")
          setOpenModal(false)
       //   history.push("/")
         }).catch((error) => {
          var errorMessage = error.message;
          window.alert(errorMessage)
        });
  
  
  
  
        }).catch((error) => {
          var errorMessage = error.message;
          window.alert(errorMessage)
        });
        
  }

  return (
    <div className="modalBackground1">
      <div className="modalContainer1"  style={{overflow:"scroll", height:"auto", maxHeight:"90vh"}}>
        <div className="titleCloseBtn">
          <button
            onClick={() => 
                setOpenModal(false)
            }
          >
            X
          </button>
        </div>
        <div className="title">
  <center> <h2 style={{color:"red"}}>  !! Update Password !! </h2> </center>

        </div>
        {loading? <ModalLoaderUi/>: <>
        <div className="body">
        <div className="inputBx1">
                 <span>Current Password </span>
                 <input type={showPassword?"text" : "password"}autoComplete="chrome-off" value={currentPassword} onChange={(e)=> setCurrentPassword(e.target.value)}/> 
               </div>
               <div className="remember">
                 <label>  <input type="checkbox" onChange={()=> setShowPassword(!showPassword)}  /> Show Password</label>
                 {/* {showPasswordErrorMessage ?<p style={{color:"#291770"}}> {passwordErrorMessage} </p> : ""} */}
               </div>
               <div className="inputBx1">
                 <span>New Password</span>
                 <input type= {showNewPassword?"text" : "password"} autoComplete="chrome-off" value={newPassword} onChange={(e)=>setNewPassword(e.target.value) }/> 
                
               </div>
               <div className="remember">
                 <label>  <input type="checkbox" onChange={()=> setShowNewPassword(!showNewPassword)}  /> Show Password</label>
                 {/* {showPasswordErrorMessage ?<p style={{color:"#291770"}}> {passwordErrorMessage} </p> : ""} */}
               </div>
        <div className="inputBx1">
                 <span>Confirm New Password</span>
                 <input type={showConfirmPassword?"text":"password"} autoComplete="chrome-off" value={confirmNewPassword} onChange={(e)=>setConfirmNewPassword(e.target.value)} /> 
               </div>
               <div className="remember">
                 <label>  <input type="checkbox"  onChange={()=> setShowConfirmPassword(!showConfirmPassword)}  /> Show Password</label>
                 {showPasswordErrorMessage ?<p style={{color:"#291770"}}> {passwordErrorMessage} </p> : ""}
               </div>

        </div>
        <div className="footer">
        <button
            onClick={() => {
             setOpenModal(false)
             
            }}
        
          >
          Cancel
          </button>
      
     
          <button
            onClick={() => {
             ChangePassword()
             
            }}
            disabled={buttonDisabled}
          >
          Update Password
          </button>
      
        </div>
        </>} 
      </div>
     
    </div>
  );
}

export default UpdatePasswordModal;