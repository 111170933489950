import React from 'react';
import '../Button.css';
import firebase from "../Common Components/firebase"
import "../Navbar.css"

const STYLES = [
    'btn--primary',
    'btn--outline'
]

const SIZES = [
    'btn--medium',
    'btn--large'
]

export const ButtonSignOut =({children,type, style, buttonStyle, buttonSize})=>{

    const checkButtonStyle = STYLES.includes(buttonStyle) ?buttonStyle:SIZES[0];
    const checkButtonSizes = STYLES.includes(buttonSize) ?buttonSize:SIZES[0];

    return(<button className={`btn ${checkButtonStyle} nav-links`}  onClick={()=> firebase.auth().signOut() }  style={style} type={type} >{children}</button>)

}
// ${checkButtonSizes}`}