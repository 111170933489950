import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import AssignmentIcon from '@material-ui/icons/Assignment';

export const SidebarData =[
    {
        title:'My Profile',
        url :'/',
 
    },

    {
        title:'Member Directory',
        url :'/member-directory',
        cName:'nav-links'
    },
 
    {
        title:"Gallery",
        url:"/gallery"
    },
    
    {
        title:"Notice Board",
        url:"/notice",
      
    }, //working-committee
    
    {
        title:"Secreteriat",
        url:"/secreteriat",
      
    },
    // {
    //     title:"E-Newsletter",
    //     url:"/e-newsletter",
      
    // },
    {
        title:"CPE Sub-Committee",
        url:"/cpe-subcommittee",
      
    },
    {
        title:"E-Newsletter Sub-Committee",
        url:"/enewsletter-subcommittee",
      
    },
    {
        title:'Update Profile',
        url :"/update-profile",
        cName:'nav-links'
    },
    {
        title:"Member Blood Bank",
        url :"/blood-bank",
        cName:'nav-links'

    }
    
    
    
    
   
   

]