import React, { Fragment, useEffect, useState, useContext } from "react";
import firebase from "../Common Components/firebase";
import { Navbar } from "../Navbar/Navbar.js";
import { useHistory, Redirect } from "react-router-dom";
import { AuthContext } from "../auth/Auth";
import { MDBContainer, MDBRow, MDBCol, MDBBtn, MDBInput } from "mdbreact";
import DatePicker from "react-datepicker";
import dateFormat from "dateformat"
import "react-datepicker/dist/react-datepicker.css";

import Switch from '@material-ui/core/Switch';
import axios from "axios";
import Sidebar from "../Navbar/SideMenuBar";
import LoaderUi from "../Common Components/LoaderUi";
import { LoginNavbar } from "../Navbar/LoginNavbar";
import IdleTimeContainer from "../Common Components/IdleTimeContainer";
import "../UpdateProfile.css";
import UpdateEmailModal from "../Modal/UpdateEmailModal";
import UpdatePasswordModal from "../Modal/UpdatePasswordModal";
import Modal from "../Modal/Modal";
import WhatsappContext from "../WhatsappContext/WhatsappContext";
import { UpdateSms } from "../Common Components/SmsCommunication";
import { UpdateEmail } from "../Common Components/EmailCommunication";
import { Email } from "@material-ui/icons";


export default function UpdateProfile() {
  const wp = useContext(WhatsappContext)
  const [name, setName] = useState("");
  const [modal, setModal] = useState(false)
  const [passwordModal, setPasswordModal] = useState(false)
  const [bloodGroup, setBloodGroup] = useState("")
  const [gender, setGender] = useState("")
  const [dob, setDob] = useState()
  const [address, setAddress] = useState("")
  const [whatsapp, setWhatsapp] = useState("");
  const [mobile, setMobile] = useState("")
  const [buttonDisabled, setButtonDisabled] = useState(true)
  const [modalOpen, setModalOpen] = useState(false)
  const [email, setEmail] = useState("")
  const [eligible, setEligible] = useState(false)
  const [mid, setMid] = useState("")
  const [loading, setLoading] = useState(false)
  const [currentCity, setCurrentCity] = useState("")
  const [firmName, setFirmName] = useState("")
  const [worktype, setWorktype] = useState("")
  const [faddress, setfaddress] = useState("")
  const [currentState, setCurrentState] = useState("")
  const history = useHistory();
  
const {currentUser} = useContext(AuthContext)
const userID = currentUser.uid
function SendUpdateWhatsapp(mobile){
  const msg = `Dear ${name},%5CnYou have successfully updated your MAYUM Muz App profile. If you haven't initiated this update please contact app administrator.%5CnRegards,%5CnMarwari Yuva Manch%5CnMuzaffarpur`

  axios.get(`${wp.api}${wp.typeTextInUrl}&${wp.mobileParamKey}=${wp.plus}${wp.add91}${mobile}&${wp.msgParamKey}=${msg}`)
      .then((res)=>{
      
      }).catch((err)=>{
  
      })
}


const ref = firebase.firestore().collection('users').doc(userID)
  function getData(){

    ref
    .get()
    .then((doc) => {

      if (doc.exists) {
        
        setMobile(doc.data().phone)
        setName(doc.data().name)
        setWhatsapp(doc.data().whatsapp)
        setEmail(doc.data().email)
        setMid(doc.data().mid)
        setBloodGroup(doc.data().bloodgroup)
        setDob(DateFormatter(doc.data().dob))
        setWorktype(doc.data().work)
        setCurrentCity(doc.data().currentcity)
        setCurrentState(doc.data().currentstate)
        setAddress(doc.data().raddress)
        setGender(doc.data().gender)
        setFirmName(doc.data().firmname)
        setfaddress(doc.data().faddress)
        doc.data().eligible == "yes" ? setEligible(true) : setEligible(false)
       

      
      
             } else {
        // doc.data() will be undefined in this case
      }
    })
    .catch((error) => {});
   
  }

  useEffect(()=>{
    getData()
  },[])



 useEffect(()=>{


  if( name.trim() !="" && bloodGroup !="NA" && dob !="" && address.trim() != ""  && whatsapp.trim() !="" &&  mobile.trim()!="" && gender!="NA" && firmName.trim() !="" && faddress.trim() !=""
  && currentCity.trim() !="" && currentState.trim() !="" && worktype !="")
    setButtonDisabled(false)
    else
      setButtonDisabled(true)
  

 },[ name, address,bloodGroup, dob, whatsapp, gender, firmName, faddress, currentCity, currentState, worktype])
 
 
 function toTitleCase(str) {
  if(str!="" && typeof(str)=="string"){
  return str.replace(
    /\w\S*/g,
    function(txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    }
  );} else return str
}

  function CheckEligible(value){
    if(value)
      return "yes"
    else
      return "no"
    
  }
  function DateFormatter(date){
      
    var d = date.split('/')
    var d1 = d[0]
    var d2 = d[1]
    var d3 = d[2]

    if(d2.length==1)
      d2="0"+d2
    
    var finalDate = d2+"/"+d1+"/"+d3+ " 00:00:00"
   return new Date(finalDate)

  }
  
  function checkMobile(value){
      if(value.length>10)
        return value.slice(0,10)
      else
        return value
  }

  const UpdateDetails =() =>{
    setLoading(true)
    const db = firebase.firestore();
    const users = db.collection("users").doc(currentUser.uid);

        users
          .update({
            raddress :address,
            bloodgroup:bloodGroup,
            dob:dateFormat(dob,"d/m/yyyy"),
            name: name.toLowerCase(),
            gender:gender,
            phone:mobile,
            whatsapp:whatsapp,
            eligible:CheckEligible(eligible),
            currentcity: currentCity,
            currentstate : currentState,
            firmname : firmName,
            faddress : faddress,
            worktype : worktype
         
          })
          .then(function () {

          //  UpdateSms(name, mobile)
          //   SendUpdateWhatsapp(whatsapp)
          //   UpdateEmail(email, name,mid)
            window.alert("Profile Successfully Updated!");
        //  setTimeout(()=>{ history.push("/")},5000)  
            window.location.href='/'
          })
          .catch(function (error) {
            window.alert(error);
          });     
  }



 

  return (
    <Fragment>
   <>
   <IdleTimeContainer />
      <LoginNavbar />
      <Navbar />
      <Sidebar />
      {modalOpen && <Modal setOpenModal={setModalOpen}/>}
      {modal && <UpdateEmailModal setOpenModal={setModal} />}
      {passwordModal && <UpdatePasswordModal setOpenModal={setPasswordModal}/>}

      <div style={{ paddingTop: "120px", zIndex: 0 }}></div>
      <MDBContainer  
    >
      <MDBRow>  

      <MDBCol md='8'></MDBCol>
      <MDBCol md="2"> <button onClick={()=> setModal(true)} className	="btn btn-primary nav-links" style={{background:"#291770", color:"white",fontSize:"20px"}}>Update Email</button>
      </MDBCol>
      <MDBCol md="2" >
        <button onClick={()=> setPasswordModal(true)} className	="btn btn-primary nav-links" style={{background:"#291770", color:"white",fontSize:"20px"}}>Update Password</button>
      </MDBCol>
      </MDBRow>
     
    </MDBContainer>
      
   <div className="d-flex justify-content-end"  >
        <div className="d-flex p-2 col-example" >
        
          </div></div>

      <div
        className="d-flex justify-content-center"

      >
        <div className="d-flex p-6 col-example"> </div>
      </div>

      <div
        className="d-flex justify-content-center"
     
      >
        <div className="d-flex p-6 col-example">
          <h1>Update Profile</h1>
        </div>
      </div>
        <div className="d-flex bd-highlight example-parent">
          <div className="p-2 flex-fill bd-highlight col-example"></div>

          <div className="p-2 flex-fill bd-highlight col-example form-group">
       
          <label style={{ fontSize: "30px", color:"#291770" }}>  Name <span style={{color:"#291770"}}>*</span> </label>
            <input
              type="text"
              className="form-control"
              placeholder="Person Name"
              style={{ width: "100%" }}
               value={toTitleCase(name)}
               onChange={(e) => setName(e.target.value)}
            />
          <label style={{ fontSize: "30px", color:"#291770" }}>Mobile No.<span style={{color:"#291770"}}>*</span> </label>
            <input
              type="number"
              className="form-control"
              placeholder="Mobile"
              style={{ width: "100%" }}
               value={mobile}
               onChange={(e)=> setMobile(checkMobile(e.target.value))}
               maxLength="10"
              
            />

            <label style={{ fontSize: "30px", color:"#291770" }}>Blood Group <span style={{color:"#291770"}}>*</span> </label><br></br>
            <select value={bloodGroup} onChange={(e)=> setBloodGroup(e.target.value)} style={{width:"100px"}}>
              <option value="NA">NA</option>
              <option value="A+">A+</option>    
              <option value="A-">A-</option>   
              <option value="B+">B+</option>   
              <option value="B-">B-</option>                 
              <option value="A+">O+</option>    
              <option value="O-">O-</option>    
              <option value="AB+">AB+</option>    
            </select> 
            <br></br> <br/>

            <label style={{ fontSize: "20px"}} > Are You Eligible for blood donation ?
            </label>  <label style={{marginLeft:"15px", fontSize: "20px", color:"#291770"}} > No
            </label>
            <Switch
            checked={eligible}
            onClick={()=> setEligible(!eligible)}
            color="primary"
            style={{ fontSize: "20px"}}
          />
          <label style={{ fontSize: "20px", color:"#291770"}} > yes
            </label>
          <br/>

            <label style={{ fontSize: "30px", color:"#291770" }}> Gender <span style={{color:"#291770"}}>*</span></label><br/>
            <select value={gender} onChange={(e)=> setGender(e.target.value)} style={{width:"170px"}}>
              <option value="">Choose</option>
              <option value="M">Male</option>    
              <option value="F">Female</option>           
            </select> 

            <br />
            <br />

            <label style={{ fontSize: "30px", color:"#291770" }} > Date of Birth <span style={{color:"#291770"}}>*</span>
            </label><br/>
            <DatePicker  selected={dob} onChange={(date) => setDob(date)} showYearDropdown yearDropdownItemNumber={100} scrollableYearDropdown />
            <br />
            <br />
           
            <label style={{ fontSize: "30px", color:"#291770" }}>
              Whatsapp No. (Without +91)<span style={{color:"#291770"}}>*</span>  
            </label>
            <input
              type="number"
              className="form-control"
              placeholder="WhatsApp"
              style={{ width: "100%" }}
              value={whatsapp}
              onChange={(e) => {setWhatsapp(checkMobile(e.target.value))}}
            />

            <label style={{ fontSize: "30px", color:"#291770" }}> Residential Address<span style={{color:"#291770"}}>*</span>  </label>
            <input
              type="text"
              className="form-control"
              placeholder="Address"
              style={{ width: "100%" }}
              value={toTitleCase(address)}
              onChange={(e) => setAddress(e.target.value)}
            />
           <label style={{ fontSize: "30px", color:"#291770" }}> Current City<span style={{color:"#291770"}}>*</span>  </label>
            <input
              type="text"
              className="form-control"
              placeholder="Address"
              style={{ width: "100%" }}
              value={toTitleCase(currentCity)}
              onChange={(e) => setCurrentCity(e.target.value)}
            />

<label style={{ fontSize: "30px", color:"#291770" }}> Current State <span style={{color:"#291770"}}>*</span> </label>
            <input
              type="text"
              className="form-control"
              placeholder="Address"
              style={{ width: "100%" }}
              value={toTitleCase(currentState)}
              onChange={(e) => setCurrentState(e.target.value)}
            />
            <label style={{ fontSize: "30px", color:"#291770" }}>Firm Name <span style={{color:"#291770"}}>*</span>  </label>
            <input
              type="text"
              className="form-control"
              placeholder="Firm Name"
              style={{ width: "100%" }}
              value={toTitleCase(firmName)}
              onChange={(e) => setFirmName(e.target.value)}
            />

            <label style={{ fontSize: "30px", color:"#291770" }}>Firm Address <span style={{color:"#291770"}}>*</span>  </label>
            <input
              type="text"
              className="form-control"
              placeholder="Firm Address"
              style={{ width: "100%" }}
              value={toTitleCase(faddress)}
              onChange={(e) => setfaddress(e.target.value)}
            />

        <label style={{ fontSize: "30px", color:"#291770" }}> Worktype<span style={{color:"#291770"}}>*</span>  </label><br/>
        <select value={worktype} onChange={(e)=> setWorktype(e.target.value)} style={{width:"170px"}}>
              <option value="">Choose</option>
              <option value="Service"> Service </option>    
              <option value="Practice">Practice</option>    
              
            </select> 
            <br />
            <br />

            <button
              disabled={buttonDisabled}
              onClick={()=>UpdateDetails()}
              className="btn btn-primary btn-block"
              style={{ width: "60%", backgroundColor: "#291770" }}
            >
              Update Profile
            </button>
          </div>

          <div className="p-2 flex-fill bd-highlight col-example"></div>
        </div>
         </>
      
    </Fragment>
  );
}
