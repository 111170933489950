import React, {Fragment, useEffect, useState} from "react";
import "./StudentBirthdayPanel.css"
import firebase from "../Common Components/firebase";
import Marquee from "react-easy-marquee"
import { placeholderManImage, toTitleCase } from "../Common Components/CommonFunctions";
  
  function StudentBirthdayPanel() {

    const[birthdays, setBirthdays] = useState([])
      function CheckDate(birthday){
        const today = new Date()
        const date = today.getDate()
        const month = today.getMonth()
        const dateString = date.toString()
        const monthString = (month+1).toString()
        const birthArray = birthday.split("/")
        if(birthArray[0] == dateString && birthArray[1]== monthString){
            return true
        }else{
            return false
        }

        


      }
      useEffect(()=>{
          let temp =[]
        const ref = firebase.firestore().collection('students').orderBy("name")
        ref.onSnapshot((querySnapshot)=>{
            querySnapshot.forEach((doc)=>{
                if(CheckDate(doc.data().dob)){
                    temp.push(doc.data())
                }
            })
            setBirthdays(temp)
        })
      },[])
   
    return (
      <Fragment>
       <div className="StudentBirthdayPanel" style={{float:"right", position:"fixed", zIndex:"300"}}>
            <div className="StudentBirthdayList1 ">
            <div className="d-flex justify-content-center"  >
        <div className="d-flex p-2 col-example" style={{ zindex:"100"}} >
        <h4 className="headings1">Student Birthdays Today</h4>
        
          </div></div>
             <hr/>
             {birthdays.length == 0 ? <li  className="row"> No Birthdays for today!!</li>:""}
             {birthdays.length <=3 ?<>{birthdays.map((val, key)=>{
                    return(
                        <li key={key} className="row" style={{marginBottom:"5px", marginTop:"25px"}}>
                            <div style={{width:"25%", marginLeft:"1%"}}><img src={val.image}  onError={(e) => {
                        e.target.src = placeholderManImage
                      }} style={{borderRadius:"50%", height:"80%", width:"100%", float:"left"}}/></div>
                            <div style={{cursor:'pointer', width:"70%"}} >{toTitleCase(val.name)}</div>                            
                        </li>

                        
                    )
               }) }</>:
             <Marquee axis="Y" reverse={true} pauseOnHover={true}  height="220px" >
               {birthdays.map((val, key)=>{
                    return(
                        <li key={key} className="row" style={{marginBottom:"30px"}}>
                            <div style={{width:"25%", marginLeft:"1%"}}><img src={val.image}  onError={(e) => {
                        e.target.src = placeholderManImage
                      }} style={{borderRadius:"50%", height:"80%", width:"100%", float:"left"}}/></div>
                            <div style={{cursor:'pointer', width:"70%"}} >{toTitleCase(val.name)}</div>                            
                        </li>

                        
                    )
               }) }
            </Marquee>
  }
            </div>

       </div>

      </Fragment>
    );
  }
  
  export default StudentBirthdayPanel;
  