import React, { Fragment, useEffect, useState, useContext } from "react";
import { LoginPageNavbar } from "../Navbar/LoginPageNavbar";
import { LoginNavbar } from "../Navbar/LoginNavbar";
import "react-datepicker/dist/react-datepicker.css";
import Switch from '@material-ui/core/Switch';
import axios from "axios";
import "../Registration.css"
import RegistrationPart2 from "./RegistrationPart2";
import Carousel from "react-material-ui-carousel";

export default function RegistrationPart1(props) {
  const[membershipId, setMid] = useState(props.membershipId)
  const [name, setName] = useState("");
  const [fathername, setFathername] = useState("");
  const [bloodGroup, setBloodGroup] = useState("")
  const [gender, setGender] = useState("")
    const [email, setEmail] = useState(props.email);
  const[showConfirmPassword, setShowConfirmPassword] = useState(false)
  const [password, setPassword] = useState(props.password);
  const [whatsapp, setwhatsapp] = useState(props.whatsapp);
  const [buttonDisabled, setButtonDisabled] = useState(true)
  const [showNextPart, setShowNextPart] = useState(false)
 
  
  const [eligible, setEligible] = useState(false)
    
  function nextPart(){
      setShowNextPart(true)
  }

    
    useEffect(()=>{
        if(name.trim() !="" && fathername.trim() !="" &&bloodGroup.trim() !="" && gender.trim() !="")
            setButtonDisabled(false)
        else
            setButtonDisabled(true)
    },[name, fathername, bloodGroup, gender])
  
  return (
    <Fragment>
        <LoginNavbar/>  
       <LoginPageNavbar/>
        {!showNextPart ?
            <section>
         <div className="imgBx">
         <Carousel className="carousel1" indicators={false} autoPlay={true}>
            {Object.values(props.images).map((doc)=>(
            <> <img src ={doc} /></> 
            ))}
          </Carousel>
          </div>
         <div className="contentBx">
           <div className="formBx">
             <h2>Registration</h2>
             
               <div className="inputBx">
                 <span>Full Name</span>
                 <input type="text" value={name} autoComplete="off" onChange={(e)=>setName(e.target.value)} /> 
                
               </div>
               <div className="inputBx">
                 <span>Father's Name</span>
                 <input type="text" value={fathername} autoComplete="off" onChange={(e)=> setFathername(e.target.value)}/> 
               </div>

               <div className="inputBx">
                 <span>Gender</span><br/>
                 <select value={gender} onChange={(e)=> setGender(e.target.value)} >         
                 <option value="">Choose</option>
                          <option value="M">Male</option>    
                         <option value="F">Female</option>           
                   </select>  
               </div>
              
               <div className="inputBx">
                 <span>Blood Group</span><br/>
                 <select value={bloodGroup} onChange={(e)=> setBloodGroup(e.target.value)} >         
                 <option value="">Choose</option>
         <option value="A+">A+</option>    
         <option value="A-">A-</option>          
          <option value="B+">B+</option>   
         <option value="B-">B-</option>                 
         <option value="A+">O+</option>    
         <option value="O-">O-</option>    
         <option value="AB+">AB+</option>            
                   </select>  
               </div>


               <div className="inputBx">
                 <span>Eligible for Blood Donation ?</span><br/>
                 <label style={{marginLeft:"15px", fontSize: "20px", color:"#291770"}} > No
       </label>
       <Switch
      checked={eligible}
       onClick={()=> setEligible(!eligible)}
       color="primary"
       style={{ fontSize: "20px"}}
     />
     <label style={{ fontSize: "20px", color:"#291770"}} > yes
       </label>
               </div>

             
               <div className="inputBx">             
                 <input type="button" value="Next"  disabled={buttonDisabled} onClick={nextPart} /> 
               </div>

            
           </div>
         </div>
       </section>

: <RegistrationPart2 name={name} gender={gender} fathername={fathername} bloodGroup={bloodGroup} email={email} password={password} whatsapp={whatsapp} membershipId={membershipId} eligible={eligible} images={props.images}/>}
         
    </Fragment>
  );
}

