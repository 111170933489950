import React, { useState, useEffect, useContext } from "react";
import { placeholderManImage } from "../Common Components/CommonFunctions";
import "./UpdateModal.css";

function  MemberImageDisplayModal({ setOpenModal, imageSrc}) {

  return (
    <div className="modalBackground1">
      <div className="modalContainer1">
        <div className="titleCloseBtn">
          <button
            onClick={() => 
                setOpenModal(false)
            }
          >
            X
          </button>
        </div>
        <div className="title">

        </div>
        <div className="body">
        <img src={imageSrc} onError={(e)=>{e.target.onerror = null; e.target.src=placeholderManImage}} width="400px" style={{
              justifyContent:"center",
              alignItems: "center",
              overflow: "hidden",
              border:"1px solid #291770",
              marginLeft : "10vw"
            }} 

            
               />
        </div>
        <div className="footer">
      
     
          <button
            onClick={() => {
            setOpenModal(false)
             
            }}
            // disabled={buttonDisabled}
          >
       Okay
          </button>
      
        </div>
       
      </div>
     
    </div>
  );
}

export default MemberImageDisplayModal;