import React, { useState,useEffect, useRef } from "react";
import axios from "axios";
import { useHistory } from "react-router";
import { Fragment } from "react";
import Registration from "./Registration";
import { LoginNavbar } from "../Navbar/LoginNavbar";
import "../Registration.css"
import firebase from "../Common Components/firebase";
import Timer from '../Common Components/Timer';
import Carousel from "react-material-ui-carousel";
import {LoginPageNavbar} from '../Navbar/LoginPageNavbar'
export default function MemberVerification() {


  const [status, setStatus] = useState("")
 
  const [otpReceived, setOtpReceived] = useState("");
  const[mid, setMid] = useState("")
  const[otpDisabled, setOtpDisabled] = useState(false)
  const[verifyDisabled, setVerifyDisabled] = useState(false)
  const[showRegistrationForm, setShowRegistrationForm] = useState(false)
  const[errorMessage, setErrorMessage] = useState("")
  const[showErrorMessage, setShowErrorMessage] = useState(false)
  const [images, setImages] = useState({})
  const[showOtpSentMessage, setShowOtpSentMessage] = useState(false)  
  const[otpToBeChecked,setOtpToBeChecked] = useState("")
  const [whatsapp, setWhatsapp] = useState("")
  const history = useHistory()
  const [count, setCount] = useState(0)
  var max = 99999;
    var min = 10000
  const [otp, setOtp] = useState(-1);
 
function getSidePanelImages(){
 firebase.firestore().collection('website').doc('sidepanel').get().then((doc)=>{
   setImages(doc.data())
 })
}

useEffect(()=>{
  getSidePanelImages()
},[])
  const otpCheck = () => {

    if (otpReceived == otpToBeChecked) {
       
        setShowRegistrationForm(true)
      
  }
  else{
      setErrorMessage("Wrong OTP Entered")
      setShowErrorMessage(true)
      
  }
}
 

const ref = firebase.firestore().collection("values").doc("user");
useEffect(() => {
  
  
  ref
    .get()
    .then((doc) => {
      if (doc.exists) {
        setStatus(doc.get(mid))
       
             } else {
        // doc.data() will be undefined in this case
      
      }
    })
    .catch((error) => {});
   
      
    setOtp(Math.floor(Math.random() * (max - min) + min))

}, [mid]);

useEffect(()=>{
  setOtp(Math.floor(Math.random() * (max - min) + min))
  setCount(0)
},[count])
  const otpGenerator = () =>{
    
    setCount(1)
       if(status=="r"){
        setErrorMessage("User Already Registered !!")
        setShowErrorMessage(true)
    } 
    else if(status==null || status==undefined ){
        setErrorMessage("Wrong MID / User not present")
        setShowErrorMessage(true)
    
    }
    else
      if( status.trim()==""){
  try{
    if(whatsapp.trim().length == 10){
      setOtpToBeChecked(otp)
 
      const msg = `OTP is ${otp} for Registration in MaYuM Muz App vide Registration No. ${mid}.%5CnWe welcome you onboard.%5CnRegards,%5CnMarwari Yuva Manch%5CnMuzaffarpur`
      axios
      .get(
        `http://us-central1-mymmuz-nearby-f58e2.cloudfunctions.net/sendWhatsapp?asdfg=${whatsapp.trim()}&qwerty=${msg}`)
      .then((res) => {
          
      })  
      setShowOtpSentMessage(true)
  
    
      setVerifyDisabled(true)
      setShowErrorMessage(false)
      setOtpDisabled(true)
   
    }else{
      window.alert("Please Enter Valid Whatsapp Number")
      setOtp("")

    }
    
  } catch(err){
      
    };

   
    
   
  }

  }
  function Edit(){
    setOtpDisabled(false)
    setShowOtpSentMessage(false)

  }

  return (
    <Fragment>
         <LoginNavbar/>  
       <LoginPageNavbar/>
        {!showRegistrationForm ?<>
        <section>
         <div className="imgBx">
          <Carousel className="carousel1" indicators={false} autoPlay={true}>
            {Object.values(images).map((doc)=>(
            <> <img src ={doc} /></> 
            ))}
          </Carousel>
         </div>
         <div className="contentBx">
           <div className="formBx"  style={{marginTop:"2px"}}>
             <h2>Registration</h2>
             
               <div className="inputBx">
                 <span>Membership Id</span>
                 <input type="text" value={mid} autoComplete="off" maxLength={4} onChange={(e)=> setMid(e.target.value)} disabled={otpDisabled}/> 
               </div>
               <div className="inputBx">
                 <span>WhatsApp</span>
                 <input type="text" maxLength={10} value={whatsapp} autoComplete="off" onChange={(e)=> setWhatsapp(e.target.value)} disabled={otpDisabled} style={{margin:"0px !important", padding:"0px !important"}}/> 
               </div>
              
               <div className="inputBx">             
               
                {!otpDisabled ?  <input type="button" value="Get Otp" onClick={()=> otpGenerator()}  style={{width:"50%", position:"inline"}} /> :  <input type="button" value="Reset" onClick={Edit}   style={{width:"50%", position:"inline"}} />}
               </div>
               
            {showErrorMessage ?<p>{errorMessage}</p>:""}
            {showOtpSentMessage?<><p> <Timer otpgen={otpGenerator}/> </p>           
              
               </>:""}
          
               
            {otpDisabled ?<>
             <div className="inputBx">
                 <span>OTP</span>
                 <input type="number" value={otpReceived} onChange={(e)=> setOtpReceived(e.target.value)}    disabled={!verifyDisabled} /> 
               </div>
               <div className="inputBx">             
                 <input type="button" value="Verify Otp" onClick={()=> otpCheck()}    disabled={!verifyDisabled}  /> 
               </div></>:""}

          {!otpDisabled ? 
               <div className="inputBx">
              <p>Already Have an account ? <a onClick={()=> history.push("/login")} style={{cursor:"pointer"}}> sign in</a></p>
               </div>:""}
           </div>
         </div>
       </section>
</>:  <Registration whatsapp={whatsapp} membershipId={mid} images={images}/>}
    </Fragment>
  );
}