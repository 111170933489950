
import React,{useState, useEffect} from 'react';

import { MenuItems } from './LoginMenuItems';
import "../LoginPageNavbar.css"
import { logo } from '../Common Components/CommonFunctions';





export const  LoginPageNavbar = () =>{

        const [clicked, setClicked] = useState(false);

     

        return(
            <nav className="NavbarItems2" ><div className="navbar-logo2"><img src={logo}  className="f" width="200px" ></img></div> 
            <div className="menu-icon" onClick={()=>setClicked(!clicked)}>
                <i className={clicked? "fas fa-times":"fas fa-bars"}></i> </div>
            <ul className={clicked? "nav-menu2 active" : "nav-menu2"}>
                {MenuItems.map((item, index) => {
                    return(<li key={index}><a className={item.title!='Services'?"nav-links2":"nav-links-portal2"}  href={item.url}  >{item.title.toUpperCase()}</a>  </li>   )                             })}
        
            
            </ul>
           
            </nav>
    
        );

}