import React, {useEffect, useState, useRef} from "react";
import IdleTimer from "react-idle-timer"
import Modal from "react-modal"
import firebase from "../Common Components/firebase"

Modal.setAppElement("#root")
const IdleTimeContainer =()=>{
  
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const IdleTimerRef = useRef(null); 
    const SessionTimeout = useRef(null);

    const Idle = ()=>{
        setModalIsOpen(true)
        SessionTimeout.current = setTimeout(signOut,120000)
    }
    const StayActive=()=>{
        setModalIsOpen(false)
        clearTimeout(SessionTimeout.current)
    }

    const signOut=()=>{
        firebase.auth().signOut()
        clearTimeout(SessionTimeout.current)

    }
    return(
        <div>
            {/* <Modal isOpen = {modalIsOpen}  >
            <MDBCloseIcon onClick={StayActive} fontSize="medium" ></MDBCloseIcon>
                <h1> Are You There ?</h1>
                <h2>You will be logged out soon...  </h2>
                <button onClick={signOut}>Log Out </button>
                <button onClick={StayActive}>Stay Active </button>
            

            </Modal> */}
            <IdleTimer ref={IdleTimerRef} timeout={300000} onIdle= {Idle} ></IdleTimer>
        </div>
    )
}

export default IdleTimeContainer