import React, { useEffect, useState } from 'react';
import Gallery from 'react-grid-gallery';
// import ArrowBackIcon from '@mui/icons-material/ArrowBack';

export default function GalleryImages({images,setShowGalleryFront}){
 
    const [imagesArr, setImagesArr ] = useState([])
    useEffect(()=>{

    let y = Object.values(images['image'])
    let temp=[]
    for(let i=0; i<y.length; i++){
        let x ={}
        x['src'] = y[i]
        x['thumbnail'] = y[i]
        x['thumbnailWidth'] = 320
        x['thumbnailHeight'] = 174
        temp.push(x)
    } 
    setImagesArr(temp)
    },[])

return(
    <>
    <div className="GallerySideMargin">
 
 <span style={{color:"red", cursor:"pointer"}} onClick={()=> setShowGalleryFront(false)}> BACK </span> <br/>
 <span><h1 >{images['title']}</h1></span>
    <Gallery images={imagesArr} margin={8} enableImageSelection={false} />
    </div>
    </>
)
}