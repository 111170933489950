import React, { useEffect, useState } from "react";

import {ExportCSV} from './ExportCSV';

import firebase from "../Common Components/firebase";



export default function MemberPortalDownload() {

  const fileName = 'ICAI_Dhanbad_Member_Details'

  const [memberData, setMemberData] = React.useState([])

 
  let  ref = firebase.firestore().collection("users").orderBy('name');
  
  function toTitleCase(str) {
    if(str!="" && typeof(str)=="string"){
    return str.replace(
      /\w\S*/g,
      function(txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      }
    );} else return str
  }
useEffect(()=>{
  const items = [];
  ref.onSnapshot((querySnapshot) => {
    //const items = [];

    querySnapshot.forEach((doc) => {
      if(doc.data().test==null)
        items.push({
        MId: doc.data().mid,
        Name:`CA ${toTitleCase(doc.data().name)}`,         
        Gender: doc.data().gender,
        Bloodgroup : doc.data().bloodgroup,
        Address: toTitleCase(doc.data().raddress),
        Phone: doc.data().phone,
        Whatsapp: doc.data().whatsapp,
        Email: doc.data().email,
        "Firm Name" : toTitleCase(doc.data().firmname),
        "Firm Address": toTitleCase(doc.data().firmaddress),
        "Firm Position" : toTitleCase(doc.data().firmposition),
        DOB: doc.data().dob,
      
      });

      
    })
  
    setMemberData(items)
  });

},[])


  return (

    <div>

     <ExportCSV csvData={memberData} fileName={fileName} buttonText ={"Export Members"}/>

  </div>

  );
}