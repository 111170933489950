import React, { useState, useEffect, useRef, useContext } from 'react'
import firebase from '../Common Components/firebase'
import SendMessage from './SendMessage'

import { AuthContext } from "../auth/Auth";

export default function Chats() {
    const scroll = useRef()
    const [messages, setMessages] = useState([])
    const [names, setNames] = useState([])
    const auth = useContext(AuthContext)
    useEffect(() => {
        firebase.firestore().collection('messages').orderBy('createdAt').limit(50).onSnapshot(snapshot => {
            setMessages(snapshot.docs.map(doc => doc.data()))
             setNames(snapshot.docs.map(doc => doc.data().name))
        })
    }, [])

    
    return (
        <div> 
        
           <div className="msgs">
                {messages.map(({ id, text, photoURL, uid }) => (
                    <div>
                        <div key={id} className={`msg ${uid === auth.currentUser.uid ? 'sent' : 'received'}`}>
                            
                            <img src={photoURL} alt="" />
                            <p>{text}</p>
                        </div>
                    </div>
                ))}
            </div>
            <SendMessage scroll={scroll} />
            <div ref={scroll}></div>
        </div>
    )
}
