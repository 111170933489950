import React, { Fragment, useEffect, useState, useContext } from "react";
import firebase from "../Common Components/firebase";
import "react-datepicker/dist/react-datepicker.css";
import "../RegistrationBusiness.css"
import MultiImageInput from 'react-multiple-image-input';
import { useHistory } from "react-router";
import Carousel from "react-material-ui-carousel";
import LoaderUi from "../Common Components/LoaderUi";
import { LoginNavbar } from "../Navbar/LoginNavbar";
import { LoginPageNavbar } from "../Navbar/LoginPageNavbar";

export default function RegistrationPart4(props) {
 const [loading, setLoading] = useState(false)
  const[membershipId, setMid] = useState(props.membershipId)
  const [name, setName] = useState(props.name);
  const [fathername, setFathername] = useState(props.fathername);
  const [bloodGroup, setBloodGroup] = useState(props.bloodGroup)
  const [gender, setGender] = useState(props.gender)
  const [dob, setDob] = useState(props.dob)
  const [doa, setDoa] = useState(props.doa)
  const [address, setAddress] = useState(props.raddress)
  const [spouse, setSpouse] = useState(props.spouse);
  const [whatsapp, setWhatsapp] = useState(props.whatsapp);
  const [email, setEmail] = useState(props.email);
  const [password, setPassword] = useState(props.password);
  const [mobile, setMobile] = useState(props.mobile);
  const [buttonDisabled, setButtonDisabled] = useState(true)
  const [marriedDisplay, setMarriedDisplay] = useState(props.marriedDisplay)
  const [showMobileError, setShowMobileError] = useState(false)
  const [mobileErrorMessage, setMobileErrorMessage] = useState("Please Enter a valid Mobile No.")
  const [images, setImages] = useState({})
  const [eligible, setEligible] = useState(false)
  const [spouseMobile, setSpouseMobile] = useState("")
  const [children, setChildren] = useState(props.children)  
  const history = useHistory()
  


  const handleSubmit = () =>{
    //e.preventDefault();
    setLoading(true)
    try{
        firebase.auth().
        createUserWithEmailAndPassword(email, password)
        .then((userCredential) => {
        // Signed in 
        var user = userCredential.user;
      
      
        userCredential.user.sendEmailVerification()
       
        UpdateDetails(user.uid);
      }).catch((error)=> window.alert(error))
    
      
    }catch(error){
    
    }


  }

  function CheckEligible(value){
    if(value)
      return "yes"
    else
      return "no"
    
  }
  
  function checkMobile(value){
      if(value.length>10)
        return value.slice(0,10)
      else
        return value
  }

 

  const UpdateDetails =(userID) =>{

    const db = firebase.firestore();
    const users = db.collection("users").doc(userID);

        users
          .set({
            address :address.toLowerCase(),
            bloodgroup:bloodGroup,
            dobirth:dob,
            doanniversary:doa,
            email:email,
            name: name.toLowerCase(),
            gender:gender,
            image:"",
            fathersname: fathername.toLowerCase(),
            mid: membershipId,
            name:name.toLowerCase(),
            password:password,
            phone:mobile,
            spouse:spouse.toLowerCase(),
            userid:userID,
            whatsapp:whatsapp,
            spousemobile: spouseMobile,
            noofchildren: children,
            eligible:CheckEligible(eligible),
            
          })
          .then(function () {
          
            UploadImage(userID)
         
          })
          .catch(function (error) {
            window.alert(error);
            
          });     
  }

  function UploadImage(userID){
    let arr = Object.values(images)
 
  
    let arr1 = arr[0].split(",")
    const ref =  firebase.storage().ref(`users/${userID}/`).child(`profile.jpg`)
    const userRef = firebase.firestore().collection("users").doc(userID)
    let arr2 = arr1[1]

    ref.putString(arr2, 'base64' ,{contentType:'image/png'}).then((snapshot)=>{
      snapshot.ref.getDownloadURL().then((url)=>{
        
        userRef.update({
          image : url,
        }).then(()=>{
          UpdateReg()
         
        }).catch(()=>{
          window.alert("Some Error Occured ! \n Please Try Again !!")
        })
       
  
      })
  }).catch(()=>{
    window.alert("Some Error Occured ! \n Please Try Again !!")
  })
}

  const UpdateReg=()=>{
    const users = firebase.firestore().collection("values").doc("user");
    //let map = new Map([mid,"r"])
   users.update({
     [membershipId]:"r"
   }).then(function(){
    history.push("/")
   }).catch(function(error){
     window.alert("Some Error Occured ! \n Please Try Again !!")
   })
  }

  if(loading){
    return (<LoaderUi/>)
  }
  
  return (
    <Fragment>
 
 <LoginNavbar/>  
   <LoginPageNavbar/>
<section>
<div className="imgBx">
<Carousel className="carousel1" indicators={false} autoPlay={true}>
            {Object.values(props.images).map((doc)=>(
            <> <img src ={doc} /></> 
            ))}
</Carousel>
</div>
<div className="contentBx">
<div className="formBx">
 <h2>Registration</h2>
 

   {marriedDisplay ?<> 
   
   <div className="inputBx">
     <span>Spouse's Mobile <i>Optional </i></span>
     <input type="text" value={spouseMobile} autoComplete="off" onChange={(e)=> setSpouseMobile(checkMobile(e.target.value))}/> 
     {showMobileError ?<p style={{color:"#291770"}}> {mobileErrorMessage} </p> : ""}
   </div>
   

   {/* <div className="inputBx">
     <span>No. of Children</span>
     <input type="number" value={children}  onChange={(e)=> setChildren(e.target.value)}/> 
   </div> */}

  
   </>:""}
   <div className="inputBx">
   <span>Profile Pic</span>
   <MultiImageInput
      images={images}
      setImages={setImages}
     
      max={1}
      theme={{
        background: '#ffffff',
        outlineColor: '#111111',
        textColor: 'rgba(255,255,255,0.6)',
        buttonColor: '#ff0e1f',
   
        
        modalColor: '#ffffff'}}
      allowCrop={false}
   
    />
    </div>
   <div className="inputBx">             
     <input type="button" value="Next"  onClick={handleSubmit} disabled={false} /> 
   </div>


</div>
</div>
</section>


         
    </Fragment>
  );
}

