import React,{Fragment, useContext, useEffect, useState} from 'react';
import firebase from "../Common Components/firebase";
import IdleTimeContainer from "../Common Components/IdleTimeContainer"
import { MDBCol, MDBIcon, MDBContainer, MDBRow} from "mdbreact";
import EmailIcon from '@material-ui/icons/Email';
import PhoneInTalkRoundedIcon from '@material-ui/icons/PhoneInTalkRounded';
import LocationOnRoundedIcon from '@material-ui/icons/LocationOnRounded';
import { IconButton } from '@material-ui/core';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import { Navbar } from '../Navbar/Navbar';
import "../MemberDirectory.css";
import "../Modal.css";
import "./BloodBank.css"
import TitleCase from 'react-title-case';
import { AuthContext } from "../auth/Auth";
import LoaderUi from '../Common Components/LoaderUi';
import SideMenuBar from "../Navbar/SideMenuBar"
import { LoginNavbar } from '../Navbar/LoginNavbar';
import { makeStyles } from '@material-ui/styles'
import { placeholderManImage } from '../Common Components/CommonFunctions';
import Modal from '../Modal/Modal';

const useClasses = makeStyles(theme => ({
  iconContainer: {
    "&:hover $icon": {
        color: 'rgb(97,85,224)',
    }
},
icon: {
    color: '#291770',
},
}))



function BloodDonation() {
 
  const classes = useClasses()
  const [schools, setschools] =useState([]);
  const [loading, setloading] = useState([false]);
  const [userType, setUserType] = useState("")
  const [selectedBloodGroup, setSelectedBloodGroup] = useState("A+")
  const[searchTerm, setSearchTerm] = useState(" ");
  const [displayArray, setDisplayArray] = useState([])
  const {currentUser} = useContext(AuthContext);
  const [users,  setusers] = useState([])
  const uid = currentUser.uid
  const [modalOpen, setModalOpen] = useState(false)

  
const changeSelectOptionHandler = (event) => {
	setSelectedBloodGroup(event.target.value);
  setSearchTerm("")
 
};


let  ref = firebase.firestore().collection("users").orderBy('name');
 function getSchools(){

   setloading(true);
   const items=[];
   const items1 = [];
   ref.onSnapshot((querySnapshot)=>{
    
     querySnapshot.forEach((doc)=>{
       items1.push(doc.data())
        if(doc.data().eligible != undefined && doc.data().eligible == "yes")
            items.push(doc.data());
        
       
     });
     setusers(items1)

     setschools(items);
     setloading(false);
   })
 }  
 
 useEffect(()=>{
   getSchools();   
 },[]);

 

 useEffect(()=>{
  let temp =[]

  for(let i=0; i<schools.length; i++){
    if(schools[i].bloodgroup === selectedBloodGroup)
      temp.push(schools[i])

  }

  setDisplayArray(temp)

 },[selectedBloodGroup, schools])


 if(loading){
   return (<LoaderUi/>)
 }







return (
    <Fragment >
     <IdleTimeContainer/>
      <LoginNavbar />
      <Navbar/>
      <SideMenuBar />
      {modalOpen && <Modal setOpenModal={setModalOpen}/>}
      <div className="divmargin">
             <div style={{paddingTop:"120px",zIndex:0}}></div>
    <MDBContainer  
    >
      <MDBRow>  

      <MDBCol md='2'></MDBCol>
      <MDBCol md="4">  <div className="card  d-flex justify-content-center " >
      <div className="p-2 col-example text-left">
        <span>     <input type="text" 
    onChange={(event)=>{
      setSearchTerm(event.target.value); }} placeholder="Search"style={{height:"50px", width:"100%", float:"left", fontSize:"20px",color:"#291770"}}/>
      </span>
 </div></div>
      
      </MDBCol>
      <MDBCol md='1'></MDBCol>
      <MDBCol md="3" ><div className="card  d-flex justify-content-center ">
    
      <div className="p-2 col-example text-left">
      <select className="form-control form-control-lg" style={{width:"100%",color:"#291770"}} value={selectedBloodGroup}onChange={changeSelectOptionHandler}  >
        <option value="A+">A+</option>
        <option value="A-">A-</option>
        <option value="B+">B+</option>
        <option value="B-">B-</option>
        <option value="O+">0+</option>
        <option value="0-">0-</option>
        <option value="AB-">AB-</option>
        <option value="AB+">AB+</option>
      </select> </div> </div>
      </MDBCol>
      <MDBCol></MDBCol>
       
      {/* <MDBCol md='3'>{userType=="admin"?<MemberPortalDownload />:""}</MDBCol> */}
      <MDBCol md='2'></MDBCol>
      </MDBRow>
     
    </MDBContainer>
   {/* <MDBContainer>
     <MDBRow>
    <MDBCol md="2"></MDBCol>
    <MDBCol md="8"><h1 style={{textAlign:'center'}}>{heading}</h1></MDBCol>
    <MDBCol md="2">    </MDBCol>
    </MDBRow>
    </MDBContainer>
    */}


    
  
  
    

      {displayArray.filter((val)=>{
        if(searchTerm.trim() == "") return val
        else if(val.name.toLowerCase().includes(searchTerm.trim().toLowerCase())) {return val}      
      }).map((school)=>(       
 
        <div  key={school.mid} >     
    
        <div  style={{padding:"20px",cursor:"pointer"}} className="d-flex bd-highlight example-parent justify-content-around">
        <div className="p-2  bd-highlight col-example" ></div>
           <div className="p-2  flex-shrink-1 bd-highlight col-example" >
          <img src={school.image}  onError={(e)=>{e.target.onerror = null; e.target.src= placeholderManImage}} className="img-circle" width="200px" style={{ width: 150,
              height: 150,
              borderRadius: 100/ 2,
              overflow: "hidden",
              borderWidth: 3,
              borderColor: "red",
            }} 
           />

</div><div className="p-2 flex-fill flex-grow-1 bd-highlight col-example" style={{textAlign:"center", border:"2px solid #291770"}} >
 
         <p style={{ fontSize:"30px" }} > <TitleCase string= {school.name} /></p>
         
         <p style={{color:"#291770", fontSize:"30px" }} > {school.mid} </p>
         <div className="iconContainer1"> 
         <IconButton href={"mailto:"+  school.email} classes={{ root:classes.iconContainer}} ><EmailIcon style={{fontSize:50}} className={classes.icon} /> </IconButton> 
           <IconButton href={"tel:"+  school.phone}  classes={{ root:classes.iconContainer}} > <PhoneInTalkRoundedIcon style={{fontSize:50}} className={classes.icon} /> </IconButton>
           <IconButton href={"https://www.google.com/maps/dir// "+  school.raddress} classes={{ root:classes.iconContainer}} > <LocationOnRoundedIcon style={{fontSize:50}} className={classes.icon} /> </IconButton>
           <IconButton href={"https://wa.me/"+ "+91"+school.whatsapp}  classes={{ root:classes.iconContainer}} ><WhatsAppIcon style={{fontSize:50}} className={classes.icon}></WhatsAppIcon></IconButton>
            
           </div>
          </div>
          
          <div className="p-2 flex-shrink bd-highlight col-example iconContainer2"  >
            
          <IconButton href={"mailto:"+  school.email} classes={{ root:classes.iconContainer}} ><EmailIcon style={{fontSize:100}} className={classes.icon} /> </IconButton> 
           <IconButton href={"tel:"+  school.phone}  classes={{ root:classes.iconContainer}} > <PhoneInTalkRoundedIcon style={{fontSize:100}} className={classes.icon} /> </IconButton>
           <IconButton href={"https://www.google.com/maps/dir// "+  school.raddress} classes={{ root:classes.iconContainer}} > <LocationOnRoundedIcon style={{fontSize:100}} className={classes.icon} /> </IconButton>
           <IconButton href={"https://wa.me/"+ "+91"+school.whatsapp}  classes={{ root:classes.iconContainer}} ><WhatsAppIcon style={{fontSize:100}} className={classes.icon}></WhatsAppIcon></IconButton>
            
            </div>
        </div></div>
        
      
      
            
      ))}
    {displayArray.length ==0 ? <>
      <div className="d-flex justify-content-center">
        <div className="d-flex p-2 col-example">
          <h1 style={{marginTop:"10px"}}>No Data to display</h1>
     </div></div>

     </> :""}
 
   </div>
  
    </Fragment>   
  );
}

export default BloodDonation;
