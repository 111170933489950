import { render } from '@testing-library/react';
import React,{useState, useEffect} from 'react';

 import "./Dropdown.css"

export const  DropdownExplore = () =>{


        return(
            <div >           
            <ul className="list1">
                <li><a  className="item" href="https://gst.gov.in/" >GST Website</a>  </li>                 
                <li><a  className="item" href="https://youtube.icaidhanbad.org/" >Youtube Channel</a>  </li>
                <li><a  className="item" href="https://fb.icaidhanbad.org/" >Facebook</a>  </li>       
                <li><a  className="item" href="https://icaidhanbad.org/privacy-policy" >Privacy Policy</a>  </li>
                <li><a  className="item" href="https://icaidhanbad.org/terms-of-service/" >Terms of Service</a>  </li>
                <li><a  className="item" href="https://icaidhanbad.org/e-pay-tnc" >E-Payments Terms and Conditions</a>  </li>
                <li><a  className="item" href="https://icaidhanbad.org/cancellation-policy" >Cancellation Policy</a>  </li>
                <li><a  className="item" href="https://icaidhanbad.org/refund-policy/" >Refund Policy</a>  </li>
                <li><a  className="item" href="https://developer.icaidhanbad.org/" >Developer Credits</a>  </li>
             
        
            </ul>
            </div>


    
        );

}