import { render } from '@testing-library/react';
import React,{useState, useEffect} from 'react';

 import "./Dropdown.css"

export const  DropdownServices = () =>{


        return(
            <div >           
            <ul className="list">
                <li><a  className="item" href="https://icaidhanbad.org/services/" >Know Us</a>  </li>                 
                <li><a  className="item" href="https://enewsletter.icaidhanbad.org/" >E-Newsletter</a>  </li>
                <li><a  className="item" href="https://newsletter.icaidhanbad.org/" >Newsletter</a>  </li>       
                <li><a  className="item" href="https://events.icaidhanbad.org/" >Events</a>  </li>
                <li><a  className="item" href="https://arpit.icaidhanbad.org/" style={{color:"#4062EA"}}  >Member Portal</a>  </li>
                <li><a  className="item" href="https://s-arpit.icaidhanbad.org/" >Student Portal</a>  </li>

            </ul>
            </div>


    
        );

}