import React from 'react';
import { AuthProvider } from './auth/Auth';
import Login from './auth/Login';
import Welcome from './Welcome';
import PrivateRoute from "./Common Components/PrivateRouter";
import { BrowserRouter as Router, Route } from "react-router-dom";
import MemberDirectory from './MemberDirectory/MemberDirectory';
import Memberdetails from './MemberDirectory/memberdetails';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter } from 'react-router-dom';
import UpdateProfile from './Update Profile/UpdateProfile';
import './App.css'
import MemberVerification from './Registration/MemberVerification';
import BloodDonation from './Blood Bank/BloodDonation';
import Notice from './Notice/Notice'
import Chats from "./Chatbox/Chats"
import Gallery from './Gallery/Gallery';
import Secreteriat from './Committees/Secreteriat';
import WhatsappState from './WhatsappContext/WhatsappState';
import StudentDirectory from './MemberDirectory/StudentDirectory';
import CPESubCommittee from './Committees/CPESubCommittee';
import ENewsLetterSubCommittee from './Committees/ENewsLetterSubcommittee';
import ENewsletter from './ENewsletter/ENewsletter';

function App() {

  return (
    <BrowserRouter>
         <AuthProvider>
          <WhatsappState>
        <div>
          <PrivateRoute exact path="/gallery" component={Gallery}/>
          <PrivateRoute exact path ="/chats" component={Chats}/>
          <PrivateRoute exact path="/" component={Welcome} />
          <Route exact path="/login" component={Login} />
          <Route exact path="/registration" component={MemberVerification} />
          <PrivateRoute exact path="/notice" component={Notice} />
          <PrivateRoute exact path="/blood-bank" component={BloodDonation} />
          <PrivateRoute exact path="/member-directory" component={MemberDirectory} />
          <PrivateRoute exact path="/student-directory" component={StudentDirectory} />
          <PrivateRoute exact path="/member-directory/:id"  component={Memberdetails} />
          <PrivateRoute exact path="/update-profile"  component={UpdateProfile} />
          <PrivateRoute exact path="/e-newsletter"  component={ENewsletter} />
          <PrivateRoute exact path="/cpe-subcommittee" component={CPESubCommittee} />
          <PrivateRoute exact path= "/enewsletter-subcommittee" component={ENewsLetterSubCommittee}/>
          <PrivateRoute exact path='/secreteriat' component={Secreteriat} />
         
        </div>
        </WhatsappState> 
        </AuthProvider>
      </BrowserRouter>
    
     
      
  
  );
}

export default App;