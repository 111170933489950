import React, { Fragment, useEffect, useState, useContext } from "react";
import DatePicker from "react-datepicker";
import dateFormat from "dateformat"
import "react-datepicker/dist/react-datepicker.css";
import Switch from '@material-ui/core/Switch';
import axios from "axios";
import "../Registration.css"
import RegistrationPart4 from "./RegistrationPart4";
import Carousel from "react-material-ui-carousel";
import { LoginNavbar } from "../Navbar/LoginNavbar";
import { LoginPageNavbar } from "../Navbar/LoginPageNavbar";
export default function RegistrationPart3(props) {
 
  const[membershipId, setMid] = useState(props.membershipId)
  const [name, setName] = useState(props.name);
  const [fathername, setFathername] = useState(props.fathername);
  const [bloodGroup, setBloodGroup] = useState(props.bloodGroup)
  const [gender, setGender] = useState(props.gender)
  const [dob, setDob] = useState(props.dob)
  const [doa, setDoa] = useState()
  const [address, setAddress] = useState(props.raddress)
  const [spouse, setSpouse] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("")
  const [whatsapp, setWhatsapp] = useState(props.whatsapp);
  const [email, setEmail] = useState(props.email);
  
  const [showMobileError, setShowMobileError] = useState(false)
  const [mobileErrorMessage, setMobileErrorMessage] = useState("Please Enter a valid Mobile No.")

  const[showConfirmPassword, setShowConfirmPassword] = useState(false)
 
  const [password, setPassword] = useState(props.password);
  const [mobile, setMobile] = useState(props.mobile);
  const [buttonDisabled, setButtonDisabled] = useState(true)
  const [isMarried, setIsMarried] = useState(false)
  const [marriedDisplay, setMarriedDisplay] = useState(false)
  const [showEmailError, setShowEmailError] = useState(false)
  const [eligible, setEligible] = useState(props.eligible)
  const [spouseEmail,setSpouseEmail] = useState("")
  const [spouseMobile, setSpouseMobile] = useState("")
  const [children, setChildren] = useState("")
  const [showNextPart, setShowNextPart] = useState(false)
 
  
    
  function nextPart(){
      setShowNextPart(true)
  }
  
    useEffect(()=>{
      if(spouseEmail.trim() == '')
        setShowEmailError(false)
    },[spouseEmail])

  const ValidateEmail = (value) =>{
    if (value !== "undefined") {

      var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
    
      if (!pattern.test(value)) {
    
        setShowEmailError(true)
        
    
       
    
      } else {
        setShowEmailError(false)
      }
    
    }
  }
    

 
  useEffect(()=>{
    if(isMarried)
      setMarriedDisplay(true)
    else{
      setMarriedDisplay(false)
      setSpouse("")
      setSpouseEmail("")
      setDoa()
      setSpouseMobile("")
      setChildren("")
    }
  },[isMarried])

  useEffect(()=>{
      if(isMarried){
        if(spouse.trim() != "" && doa !=""&& children.trim() !="")
            setButtonDisabled(false)
        else
            setButtonDisabled(true)
      } else{
          setButtonDisabled(false)
      }

  },[isMarried, doa, spouse])
 
 function toTitleCase(str) {
  if(str!="" && typeof(str)=="string"){
  return str.replace(
    /\w\S*/g,
    function(txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    }
  );} else return str
}

  function CheckEligible(value){
    if(value)
      return "eligible"
    else
      return "ineligible"
    
  }
  
  function checkMobile(value){
      if(value.length>10)
        return value.slice(0,10)
      else
        return value
  }
  useEffect(()=>{
    if(spouseMobile.length != 10 && spouseMobile.length != 0){
      setShowMobileError(true)
    }
    else
      setShowMobileError(false)
  },[spouseMobile])
  

  return (
    
    <Fragment>
      <LoginNavbar/>
      <LoginPageNavbar/>
    {!showNextPart ? 

            <section>
         <div className="imgBx">
         <Carousel className="carousel1" indicators={false} autoPlay={true}>
            {Object.values(props.images).map((doc)=>(
            <> <img src ={doc} /></> 
            ))}
          </Carousel>       
         </div>
         <div className="contentBx">
           <div className="formBx">
             <h2>Registration</h2>
             
               <div className="inputBx">
                   <label style={{ fontSize: "20px"}} > Are You Married ?
     </label>  <label style={{marginLeft:"15px", fontSize: "20px", color:"#291770"}} > No
       </label>    <Switch
      checked={isMarried}
      onClick={()=> setIsMarried(!isMarried)}
      color="primary"
      style={{ fontSize: "20px"}}
    />
    <label style={{ fontSize: "20px", color:"#291770"}} > yes
      </label>
    <br/>
               </div>
               {marriedDisplay ?<> 
               <div className="inputBx">
                 <span>Spouse's Name</span>
                 <input type="text" value={spouse} autoComplete="off" onChange={(e)=> setSpouse(e.target.value)}/> 
               </div>
            

               <div className="inputBx">
                 <span>No. of Children</span>
                 <input type="number" value={children} autoComplete="off" onChange={(e)=> setChildren(e.target.value)}/> 
               </div>

               <div className="inputBx">
                 <span>Date of Anniversary</span><br/>
                 <DatePicker selected={doa} onChange={(date) => setDoa(date)} maxDate={new Date()} showYearDropdown yearDropdownItemNumber={100} scrollableYearDropdown/>
               </div>
               </>:""}
               <div className="inputBx">             
                 <input type="button" value="Next"  onClick={nextPart} disabled={buttonDisabled} /> 
               </div>

            
           </div>
         </div>
       </section>
         :<RegistrationPart4 name={name} gender={gender} fathername={fathername} bloodGroup={bloodGroup} email={email} password={password} mobile={mobile} membershipId={membershipId} eligible={eligible} whatsapp={whatsapp}  address={address} dob={dob} doa={doa ?dateFormat(doa,"d/m/yyyy"):""} spouse={spouse} children={children} marriedDisplay={marriedDisplay} images={props.images}/>}

         
    </Fragment>
  );
}

