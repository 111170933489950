import { MDBContainer, MDBRow, MDBCol, MDBBtn, MDBInput } from "mdbreact";
import React, { useCallback, useContext,useEffect,useState } from "react";
import { Fragment } from "react";
import { withRouter, Redirect,useHistory } from "react-router";
import app from "../Common Components/firebase";
import { AuthContext } from "./Auth.js";
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import {LoginNavbar} from '../Navbar/LoginNavbar';
import {LoginPageNavbar} from '../Navbar/LoginPageNavbar';
import Carousel from "react-material-ui-carousel";
import './Login.css'
const Login = ({ history }) => {
  const a = useHistory();
  const [showPassword, setShowPassword] = useState(false)
  const [password1, setPassword1] = useState("")
  const [images, setImages] = useState({})
   
function getSidePanelImages(){
  app.firestore().collection('website').doc('sidepanel').get().then((doc)=>{
    setImages(doc.data())
  })
 }
 
  const handleLogin = useCallback(
    async event => {
      event.preventDefault();
      const { email, password } = event.target.elements;
      try {

      app.auth().setPersistence(app.auth.Auth.Persistence.SESSION)
  .then(() => {
    // Existing and future Auth states are now persisted in the current
    // session only. Closing the window would clear any existing state even
    // if a user forgets to sign out.
    // ...
    // New sign-in will be persisted with session persistence.
      setPassword1(password.value)
     return app.auth().signInWithEmailAndPassword(email.value, password.value);

  }).catch((error)=>{

    window.alert(error)
  })
  
        // await app
        //   .auth()
        //   .signInWithEmailAndPassword(email.value, password.value);
        // history.push("/");
      } 
      catch (error) {
         window.alert(error);
      }
    },
    [history]
  );

  useEffect(()=>{
    getSidePanelImages()
  },[])
  const { currentUser } = useContext(AuthContext);
  const UpdatePasswordOnLogin=(x)=>{
    const users = app.firestore().collection("users").doc(currentUser.uid);
    //let map = new Map([mid,"r"])
   users.update({
     password:x
   }).then(function(){

   }).catch(function(error){
    
   })
  }

  const forgotPassword = () => {
  
    const Email = window.prompt("Enter the Email")

    if(Email!=null && Email!=""){
    app.auth().sendPasswordResetEmail(Email)
        .then(function () {
            alert('Please check your email...')
        }).catch(function (e) {
            
        }) 
      }
    }
  if (currentUser) {
    UpdatePasswordOnLogin(password1)
    return <Redirect to="/" />;
  }

  return (   
    <Fragment>
       <LoginNavbar/>  
       <LoginPageNavbar/>         {/* <div style={{paddingTop:"120px",zIndex:0}}></div> */}
       <section>
         <div className="imgBx">
         <Carousel className="carousel1" indicators={false} autoPlay={true}>
            {Object.values(images).map((doc)=>(
            <> <img src ={doc} /></> 
            ))}
          </Carousel>
         </div>
         <div className="contentBx">
           <div className="formBx">
             <h2>Member Portal Login</h2>
             <form onSubmit={handleLogin}>
               <div className="inputBx">
                 <span>Username</span>
                 <input type="text" name="email" /> 
               </div>
               <div className="inputBx">
                 <span>Password</span>
                 <input type={showPassword ?"text":"password" }name="password" /> 
               </div>
               <div className="remember">
                 <label>  <input type="checkbox" name="" onChange={()=> setShowPassword(!showPassword)} /> Show Password</label>
               </div>
               <div className="remember">
                 <label style={{cursor:"pointer"}} onClick={()=> forgotPassword()}> Forgot Password </label>
               </div>
               <div className="inputBx">
                 
                 <input type="submit" value="Sign In" /> 
               </div>
               <div className="inputBx">
              {/* <p>Dont Have an account ? <a onClick={()=> history.push("/registration")} style={{cursor:"pointer"}}> sign up</a></p> */}
               </div>
             </form>
           </div>
         </div>
       </section>
    </Fragment>
  );
};

export default withRouter(Login);




// <Fragment>
   
