import React, {
  Fragment,
  useEffect,
  useState,
  useContext,
} from "react";
import firebase from "./Common Components/firebase";
import { AuthContext } from "./auth/Auth";
import "./card.css";
import IdleTimeContainer from "./Common Components/IdleTimeContainer";
import { Navbar } from "./Navbar/Navbar";
import { useHistory } from "react-router";
import {LoginNavbar} from './Navbar/LoginNavbar'
import SideMenuBar from "./Navbar/SideMenuBar";
import LoaderUi from "./Common Components/LoaderUi";
import Modal from "./Modal/Modal"
import BirthdayPanel from "./Panels/BirthdayPanel";
import { placeholderManImage, toTitleCase } from "./Common Components/CommonFunctions";
import ImageModal from "./Modal/ImageModal";
import StudentBirthdayPanel from "./Panels/StudentBirthdayPanel";

function Welcome() {
  const [schools, setschools1] = useState([]);
  const [loading, setloading1] = useState([false]);
  const [modalOpen, setModalOpen] = useState(false)
  const [imageModalOpen, setImageModalOpen] = useState(false)
  const storage = firebase.storage();
  const history = useHistory()


  const ref = firebase.firestore().collection("users");

  const { currentUser } = useContext(AuthContext);
 
  const userId = currentUser.uid;

  useEffect(()=>{
    ref.doc(userId).get().then((doc)=>{
      if(doc.exists){
        if(doc.data().type == "student"){
          window.alert("Unauthorized Access !!")
          firebase.auth().signOut()
        }
      }else{
        window.alert("Unauthorized Access !!")
        firebase.auth().signOut()
      }
    })
  },[])
  function getSchools() {
    
    setloading1(true);

    ref.onSnapshot((querySnapshot) => {
      const items = [];

      querySnapshot.forEach((doc) => {
        if (doc.data().userid === currentUser.uid) items.push(doc.data());
      });
      setschools1(items);
     
     
    });


   
    setloading1(false);
  }

  useEffect(() => {
    getSchools();
  }, []);

  if (loading) {
    return (<LoaderUi/>);
  }


  return (
    <Fragment>

     
      <LoginNavbar />
      <Navbar />
      <SideMenuBar />
      <IdleTimeContainer />
      <div className="mobileViewDisappear">
      <BirthdayPanel/>
      <StudentBirthdayPanel/>
      </div>
      {modalOpen && <Modal setOpenModal={setModalOpen} />}
      {imageModalOpen && <ImageModal setOpenModal={setImageModalOpen} />}
      <div style={{ paddingTop: "8vh" }}></div>
      <div
        style={{ paddingTop: "30px", paddingBottom: "30px" }}
        className="justify-content-center "
      >
        <div className="d-flex bd-highlight example-parent">
          <div className="p-2 flex-fill bd-highlight col-example"></div>

          {schools.map((school) => (
            <div key={school.userid}>
              <div className="card1 ">
                <div className="upper-container">
                  <div className="image-container">
                    <label for="file-input">
                      <img
                        src={school.image}
                        onError={(e) => {
                          e.target.onerror = null;
                          e.target.src = placeholderManImage
                        }}
                        style={{cursor:"pointer"}}
                        onClick={()=> setImageModalOpen(true)}
                        
                        width="200px"
                      />
                    </label>
                  </div>
                </div>
                <div className="lower-container">
                  <h3>  {toTitleCase(school.name)}</h3>
                  <h4>{school.mid}</h4>
                  <h3 style={{ color: "yellowgreen" }}>Personal Details</h3>
                  <p>Date of Birth : {school.dob} </p>
                  <p>Blood Group: {school.bloodgroup}</p>
                  <p> Gender : {school.gender}</p>
                  {school.married== "yes" &&(<> <h3 style={{ color: "yellowgreen" }}>Marriage Details</h3>
                  {(school.doanniversary != "" && school.doanniversary != undefined) ? <p>DOA: {school.doanniversary} </p> :""}
                  {(school.spousename != "" && school.spousename != undefined) ? <p>Spouse: {toTitleCase(school.spousename)} </p> :""}
                  {(school.spousemob != "" && school.spousemob != undefined) ? <p>Spouse's Mobile: {toTitleCase(school.spousemob)} </p> :""}
                  </>)}
                  <h3 style={{ color: "yellowgreen" }}>Contact Details</h3>
                  {school.raddress ?  <p> Address: {toTitleCase(school.raddress)}</p>:"" }
                  {school.email !="" ? <p>Email: {school.email}</p>:""}
                  {school.phone !="" ?  <p>Phone: {school.phone}</p> :""}
                 {school.whatsapp !="" ?  <p>Whatsapp: {school.whatsapp}</p> :""}
                  {school.currentcity  != "" ?  <p>Current City: {toTitleCase(school.currentcity)}</p> :""}
                  {school.currentstate  != "" ? <p>Current State: {toTitleCase(school.currentstate)}</p>:""}
            

                  <h3 style={{ color: "yellowgreen" }}>Organization  Details</h3>
                  {school.firmname != "" ?   <p>Firm Name: {toTitleCase(school.firmname)}</p> :""} 
                  {school.faddress != "" ?  <p>Firm Address: {toTitleCase(school.faddress)}</p> :""}
                  {school.work != "" ?  <p>Position: {toTitleCase(school.work)}</p> : ""}
              
            <p style={{fontStyle:"italic",  color:'red', paddingBottom:"20px"}}><b>Click on Profile Picture to Update!!</b></p>
                                </div>
              </div>
            </div>
          ))}
          <div className="p-2 flex-fill bd-highlight col-example"></div>
        </div>
      </div>
    </Fragment>
  );
}

export default Welcome;
