import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "../auth/Auth";
import { useHistory } from "react-router";
import "./UpdateModal.css";
import firebase from "../Common Components/firebase";
import LoaderUi from "../Common Components/LoaderUi";
import ModalLoaderUi from "../Common Components/ModalLoaderUi";
import {UpdateSms} from "../Common Components/SmsCommunication";
import MultiImageInput from 'react-multiple-image-input';

function ImageModal({ setOpenModal}) {
  const history = useHistory()
  const [email,setEmail] = useState("")
const [name, setName] = useState("")
  const [membershipId, setMembershipId]= useState("")
  const [mobile, setMobile] = useState("")
  const [newEmail, setNewEmail] = useState("")
  const [showEmailError, setShowEmailError] = useState(false)
  const [errorMessage1, setErrorMessage] =  useState("Please Enter a valid Email")
  const [password, setPassword] = useState("")
    const [loading, setLoading] = useState(false)
    const [images, setImages] = useState({})
  const {currentUser} = useContext(AuthContext)
  const currentUserId = currentUser.uid;
  const [buttonDisabled, setButtonDisabled] = useState(true)
  const ref = firebase.firestore().collection("users").doc(currentUserId);
 
  useEffect(() => {
    ref
      .get()
      .then((doc) => {
        if (doc.exists) {
         
          setEmail(doc.data().email)
            setName(doc.data().name)
          setMobile(doc.data().phone)
          setMembershipId(doc.data().mid)
       }
       
       else {
        }
      })
      .catch((error) => {});

    
  }, []);
 
    function UploadImage(){

      setLoading(true)
        let arr = Object.values(images)

      
        let arr1 = arr[0].split(",")
        const ref =  firebase.storage().ref(`users/${currentUserId}/`).child(`profile.jpg`)
        const userRef = firebase.firestore().collection("users").doc(currentUserId)
        let arr2 = arr1[1]
    
        ref.putString(arr2, 'base64' ,{contentType:'image/png'}).then((snapshot)=>{
          snapshot.ref.getDownloadURL().then((url)=>{
            
            userRef.update({
              image : url,
            }).then(()=>{
                window.alert("Your Profile Picture has been updated !!")
                // window.location.reload()
                UpdateSms(name, mobile)
                setOpenModal(false)
            }).catch(()=>{
              window.alert("Some Error Occured ! \n Please Try Again !!")
            })
           
      
          })
      }).catch(()=>{
        window.alert("Some Error Occured ! \n Please Try Again !!")
      })
    }
    
 


  return (
    <div className="modalBackground1">
      <div className="modalContainer1">
        <div className="titleCloseBtn">
          <button
            onClick={() => 
                setOpenModal(false)
            }
          >
            X
          </button>
        </div>
        <div className="title">
  <center> <h2 style={{color:"red"}}>  !! Update Email !! </h2> </center>

        </div>
        {loading ? <ModalLoaderUi/> :<>
        <div className="body">
        
            <MultiImageInput images={images}
      setImages={setImages}
     
      max={1}
      theme={{
        background: '#ffffff',
        outlineColor: '#111111',
        textColor: 'rgba(255,255,255,0.6)',
        buttonColor: '#ff0e1f',
   
        
        modalColor: '#ffffff'}}
      allowCrop={false}/>

        </div>
        <div className="footer">
        <button
            onClick={() => {
             setOpenModal(false)
             
            }}
        
          >
          Cancel
          </button>
      
     
          <button
            onClick={() => {
            UploadImage()
            }}
            // disabled={buttonDisabled}
          >
          Update Image
          </button>
      
        </div>
        </>}
      </div>
   
    </div>
  );
}

export default ImageModal;