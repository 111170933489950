import React, { useEffect, useState } from "react"
import { AuthContext } from "../auth/Auth";
import IdleTimeContainer from "../Common Components/IdleTimeContainer"
import GalleryUploadModal from "../Modal/GalleryUploadModal";
import { LoginNavbar } from "../Navbar/LoginNavbar"
import { Navbar } from "../Navbar/Navbar";
import SideMenuBar from "../Navbar/SideMenuBar";
import firebase from "../Common Components/firebase";
import "./Gallery.css"
import MediaQuery from "react-responsive";

import Carousel from 'react-material-ui-carousel'
import { DeleteForeverOutlined, EditAttributesOutlined, EditOutlined } from "@material-ui/icons";
import GalleryUpdateModal from "../Modal/GalleryUpdateModal";
import GalleryImages from "./GalleryImages";
export default function Gallery(){
    const [galleryModalOpen, setGalleryModalOpen] =  useState(false)
    const [publisher, setPublisher] = useState("")
    const [currentUserType, setCurrentUserType] = useState("")
    const [yearArray, setYearArray] = useState([])
    const [selectedYear, setSelectedYear] = useState("")
    const [gallery, setGallery] = useState([])
    const [displayArray, setDisplayArray] = useState([])
    const [galleryUpdateModalOpen, setGalleryUpdateModalOpen] = useState(false)
    const [imageObject, setImageObject] = useState({})
    const [showGalleryImages, setShowGalleryImages] = useState(false)
    const [showGalleryImagesObj, setShowGalleryImagesObj] = useState({})




    const userid = firebase.auth().currentUser.uid

    useEffect(()=>{
        const ref = firebase.firestore().collection("users").doc(userid)
         ref.get().then((doc)=>{
        setPublisher(doc.data().name)
        setCurrentUserType(doc.data().type)
          })
        },[])

        function setYearArrayFn(){
            const date = (new Date()).getFullYear()
            setSelectedYear(date.toString())
            let temp=[]
            for(let i=0; i<10; i++){
              temp.push((date-i).toString())
            }
            setYearArray(temp)
          }
          useEffect(()=>{
             
              setYearArrayFn()
              getGallery()
             
          },[])
          function getGallery(){
            const ref = firebase.firestore().collection("gallery").orderBy('time','desc')
            ref.onSnapshot((querySnapshot)=>{
              let temp =[]
              querySnapshot.forEach((doc)=>{
                temp.push(doc.data())
              })
              setGallery(temp)
            })
            
          }

          useEffect(()=>{
            if(selectedYear !=""){
              let temp=[]
              for(let i=0; i< gallery.length; i++){
                if(gallery[i]['year'] == selectedYear && gallery[i]['userid'] == userid){
                    temp.push(gallery[i])
                }

              }
              setDisplayArray(temp)
            }
          },[selectedYear, gallery])

          function DeleteGallery(id){
              
              let x = window.confirm("Are You Sure?\nThis can't be undone")
              if(x){
                const ref = firebase.firestore().collection("gallery").doc(id)
                ref.delete().then(()=>{
                  window.alert("Post Deleted !!")
                  window.location.reload()
                })
              }
          }

          function EditGallery(imgobj){
            setImageObject(imgobj)
            setGalleryUpdateModalOpen(true)

            
          }

          function ShowGalleryImagesFunc(img){
            setShowGalleryImagesObj(img)
            setShowGalleryImages(true)
          }
    return(
        <>
        <IdleTimeContainer />
      <LoginNavbar />
      <SideMenuBar />
      <Navbar />
      {galleryModalOpen && (<GalleryUploadModal setOpenModal={setGalleryModalOpen} publisher={publisher} userid={userid }/>)}
      {galleryUpdateModalOpen && (<GalleryUpdateModal setOpenModal={setGalleryUpdateModalOpen} imageObject={imageObject} userid={userid} />)}
      <div style={{paddingTop:"120px"}}></div>
      {!showGalleryImages ? 
    <>
     {currentUserType == "admin" ?  <div className="d-flex justify-content-end">
        <div className="d-flex p-2 col-example">
          <button
            onClick={() => setGalleryModalOpen(true)}
            className="btn btn-primary btn-block"
            style={{
              background: "#291770",
              fontSize: "20px",
              marginBottom: "15px",
            }}
          >
              Upload Gallery
          </button>
          <br />
          <br />
        </div>
      </div>:""}
      <span className="GallerySideMargin">Select Year</span> <br/>
    <select style={{ width:"40%", borderRadius:"30px", fontSize:"16px", padding:"10px"}} className="GallerySideMargin"value={selectedYear} onChange={(e)=> setSelectedYear(e.target.value)}> 
    {yearArray.map((doc,key)=>(<option value={doc}>{doc}</option>))}
    </select>
    <div className="GallerySideMargin">

    {displayArray.length>0 ? <> {displayArray.map((doc,val)=>(     
  
  <div style={{marginLeft:"20px", width:"10%", display:"inline", cursor:"pointer"}}  > 

          <article className="Post" style={{marginBottom:"15px",marginTop:"15px", background:"#4062EA"}} key={val} onClick={()=> ShowGalleryImagesFunc(doc)}>

            {/* <header>

              <div className="Post-user">

                {/* <div className="Post-user-profilepicture">

                  <img src={doc.profilepic}   onError={(e) => {
                        e.target.src =
                        "https://firebasestorage.googleapis.com/v0/b/vip-ca.appspot.com/o/VIPCA_Logo_BGLess.png?alt=media&token=c8b18e32-e8c6-492b-98b6-fce6a60c3fae";
                        e.target.onError = null;
                      }}/>

                </div> 

                <div className="Post-user-nickname">

                  {/* <span> CA {toTitleCase(doc.name.toString())} </span> 

                </div>

              </div>

            </header>
             */}
            <div className="Post-image">

              <div className="Post-image-bg">
        
              <Carousel className="carousel" autoPlay={true} interval={500000} indicators={false} stopAutoPlayOnHover={true} transitionTime={0} showArrows= {false} fullHeightHover={true}>

            {Object.values(doc.image).map((doc1, key1)=>(
           
              
              <img src={doc1} style={{width:"100% !important",height:"300px" }}  /> 
              
         

            ))}
               
                
                </Carousel>
               </div>

            </div>
           
            <div className="Post-caption" style={{background:"white"}}>

              {/* <strong>  CA {toTitleCase(doc.name.toString())} </strong>  */}
            <strong>  {doc.title} </strong> 
              <div className="d-flex justify-content-end">
        <div className="d-flex p-2 col-example">
            {/* <CalendarTodayIcon style={{fontSize:"15px"}} /> <p style={{fontSize:"10px", color:"black", marginLeft:"3px"}} > {doc.time} </p> */}
        {doc.userid== userid? <><DeleteForeverOutlined style={{fontSize:"35px", cursor:"pointer"}} onClick={()=>DeleteGallery(doc.id)}/> <EditOutlined style={{fontSize:"35px", marginLeft:"15px"}} onClick={()=>  EditGallery(doc)} /></>:""}
    </div></div>


            </div>

          </article>
          </div>
            
          ))}
          </>:<h1>No Data to display</h1>}
          </div>
 </> : <GalleryImages setShowGalleryFront={setShowGalleryImages} images={showGalleryImagesObj}/>}
  </>
  )
}