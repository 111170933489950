import axios from "axios";

export  function UpdateSms(name, mobile){
    const msg = `Dear ${name},%5CnYou have successfully updated your profile and contact information. If you haven't requested this update, kindly change your ICAI Dhn password as soon as possible or contact the administrator.%5CnRegards,%5CnDhanbad Branch of CIRC of ICAI`
    // axios
    // .get(
    //   `https://us-central1-mymmuz-nearby-f58e2.cloudfunctions.net/sendSMS?asdfg=${mobile}&zxcvb=${msg}&dest=ICAIDN&qwerty=1207161846981805337`)
    // .then((res) => {
        
    // })  
}

export  function OTPSms(otp, membershipId, mobile){
    const msg = `OTP is ${otp} for Registration in MaYuM Muz App vide Registration No. ${membershipId}.%5CnWe welcome you onboard.%5CnRegards,%5CnMarwari Yuva Manch%5CnMuzaffarpur`
    // axios
    // .get(
    //   `https://us-central1-mymmuz-nearby-f58e2.cloudfunctions.net/sendOTPSMS?asdfg=${mobile}&zxcvb=${msg}&dest=MYMMUZ&qwerty=1207161528156376487`)
    // .then((res) => {
        
    // })  
 
}

export function BirthdaySms(birthdayname, wishername, mobile ){
    const msg = `Dear ${birthdayname},%5Cn${wishername} wishes you a very Happy Birthday. Have a great and healthy year ahead%5CnRegards,%5CnMarwari Yuva Manch%5CnMuzaffarpur`
    // axios
    // .get(
    //   `https://us-central1-mymmuz-nearby-f58e2.cloudfunctions.net/sendSMS?asdfg=${mobile}&zxcvb=${msg}&dest=MYMMUZ&qwerty=1207164155069368738`)
    // .then((res) => {
        
    // })  
}

export function AnniversarySms(anniversaryname, wishername, mobile){

    const msg = `Dear ${anniversaryname},%5Cn${wishername} wishes you and your spouse a very Happy Anniversary. Have a great and healthy year ahead%5CnRegards,%5CnMarwari Yuva Manch%5CnMuzaffarpur`
    // axios
    // .get(
    //   `https://us-central1-mymmuz-nearby-f58e2.cloudfunctions.net/sendSMS?asdfg=${mobile}&zxcvb=${msg}&dest=MYMMUZ&qwerty=1207164155089226893`)
    // .then((res) => {
        
    // })  





}

export function LoginSms(name, phone){
    const msg = `Dear ${name},%5CnYou have successfully logged into your MAYUM Account.%5CnIf you haven't done this, kindly change your MAYUM password as soon as possible or contact the administrator.%5CnRegards,%5CnMarwari Yuva Manch%5CnMuzaffarpur`
    // axios
    // .get(
    //   `https://us-central1-mymmuz-nearby-f58e2.cloudfunctions.net/sendSMS?asdfg=${phone}&zxcvb=${msg}&dest=MYMMUZ&qwerty=1207164155366965539`)
    // .then((res) => {
        
    // })  

}
