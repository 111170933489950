import React from "react";
import { useHistory } from "react-router";
import "./Modal.css";

function Modal({ setOpenModal}) {
  const history = useHistory()
  return (
    <div className="modalBackground">
      <div className="modalContainer">
        {/* <div className="titleCloseBtn">
          <button
            onClick={() => 
                setOpenModal(false)
            }
          >
            X
          </button>
        </div> */}
        <div className="title">
        <center> <h2 style={{color:"red"}}>  !! Important Message !! </h2> </center>

        </div>
        <div className="body">
        <h3>Please Add Atleast a Business to Continue</h3>



        </div>
        <div className="footer">
          <button
            onClick={() => {
              setOpenModal(false);
              history.push("/my-profession")
            }}
         
          >
          Okay
          </button>
      
        </div>
      </div>
    </div>
  );
}

export default Modal;