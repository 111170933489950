import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "../auth/Auth";
import { useHistory } from "react-router";
import "./UpdateModal.css";
import firebase from "../Common Components/firebase";
import ModalLoaderUi from "../Common Components/ModalLoaderUi";
import { el, tr } from "date-fns/locale";

function NoticeEditModal({ setOpenModal, noticeId}) {
    const [text, setText] = useState("");
    const [buttonDisabled, setButtonDisabled] = useState(true)
    const [url, setUrl] = useState("")
    const [loading, setLoading] = useState(false)
    const [title, setTitle] = useState('')
    const [image, setImage] = useState("")
    const [imagePresent, setImagePresent] = useState(false)

    
    useEffect(()=>{
        if(title.trim()!=""){
            setButtonDisabled(false)
        }
        else{
            setButtonDisabled(true)
        }
    },[title])
    
    const handleChange =async () => {
      
      
    
      const storageRef = firebase
        .storage()
        .ref(`notice/${noticeId}/`)
        .child("notice");
      await  storageRef.put(image)
      setUrl(await storageRef.getDownloadURL())

    
      
    };
    
    function GetNoticeData(){
        const ref = firebase.firestore().collection("notice").doc(noticeId)
        ref.get().then((doc)=>{
            setText(doc.data().text)
            setTitle(doc.data().title)
            setUrl(doc.data().url)
        })
    }
    

    useEffect(()=>{
        GetNoticeData()
    },[])
    

    useEffect(()=>{
      if(image == ""){
        setImagePresent(false)
      }else{
        setImagePresent(true)
      }
    },[image])
    


    
    const {currentUser} = useContext(AuthContext)
    
    const ref1 = firebase.firestore().collection("users").doc(currentUser.uid);
                
                

    function singleToDouble(str) {
      if (str.length == 1)
        //let x=
        return `0${str}`;
    
      return str;
    }

    useEffect(()=>{
     if(image != "" && url !=""){
      const users = firebase.firestore().collection("notice").doc(noticeId);
    
      users
        .update({
          text: text,
          title: title,
          url:url,
        })
        .then(function () {
      
          window.alert("Notice Successfully Edited!");
            setOpenModal(false)
            window.location.reload();
        })
        .catch(function (error) {
          window.alert(error);
        });
     }
    },[image, url])
    

    
    const UpdateDetails = () => {
      //const users = newRef.doc(eventId);
      setLoading(true)
      if(image !=""){
        handleChange()
      }else{

        const users = firebase.firestore().collection("notice").doc(noticeId);
    
      users
        .update({
          text: text,
          title: title,
          url:url,
        })
        .then(function () {
      
          window.alert("Notice Successfully Edited!");
            setOpenModal(false)
            window.location.reload()
        
        })
        .catch(function (error) {
          window.alert(error);
        });
    
      }
    
        
    };
    
    
  return (
    <div className="modalBackground1">
      <div className="modalContainer1" style={{height:"90vh"}}>
        <div className="titleCloseBtn">
          <button
            onClick={() => 
                setOpenModal(false)
            }
          >
            X
          </button>
        </div>
        <div className="title">
  <center> <h2 style={{color:"red"}}>  !! Edit Notice !! </h2> </center>

        </div>
        {loading? <ModalLoaderUi/>: <>
        <div className="body">
        <div className="inputBx1">
                <span>Notice Title</span>
                <input type="text" value={title} onChange={(e)=> setTitle(e.target.value)}/>
               </div>
               <div className="inputBx1">
                 <span>Notice Content</span>
                <textarea  onChange={(e)=> setText(e.target.value)} value={text} />
               </div>
        <div className="inputBx1">
            {url != "" ? <><a href={url} target="_blank"> File Present -- Click To View File</a> 
            <p onClick={()=>setUrl("")} style={{cursor:"pointer", color:"red"}}>Remove File</p>
            Note:- New File upload will overwrite the current file
            </>:""} <br/>
            
          <span> Notice File </span>
              <input type="file" id="imageFileInput" onChange={(e)=> {setUrl("");setImage(e.target.files[0])}} />
           {imagePresent ?   <p style={{color:"red", float:"right", cursor:"pointer"}} onClick={()=>{setImage(""); document.getElementById("imageFileInput").value=""}}>Cancel File</p> :""} 
               </div>

        </div>
        <div className="footer">
        <button
            onClick={() => {
             setOpenModal(false)
             
            }}
        
          >
          Cancel
          </button>
      
     
          <button
            onClick={() => {
            UpdateDetails()
             
            }}
            disabled={buttonDisabled}
          >
          Upload Notice
          </button>
      
        </div>
        </>} 
      </div>
     
    </div>
  );
}

export default NoticeEditModal;





































