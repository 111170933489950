import React,{Fragment, useContext, useEffect, useState} from 'react';
import firebase from "../Common Components/firebase";
import IdleTimeContainer from "../Common Components/IdleTimeContainer"
import { MDBCol, MDBIcon, MDBContainer, MDBRow} from "mdbreact";
import EmailIcon from '@material-ui/icons/Email';
import PhoneInTalkRoundedIcon from '@material-ui/icons/PhoneInTalkRounded';
import LocationOnRoundedIcon from '@material-ui/icons/LocationOnRounded';
import { IconButton } from '@material-ui/core';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import { Navbar } from '../Navbar/Navbar';
import "../MemberDirectory.css";
import { AuthContext } from "../auth/Auth";
import SideMenuBar from '../Navbar/SideMenuBar'
import { LoginNavbar } from '../Navbar/LoginNavbar';
import LoaderUi from '../Common Components/LoaderUi'
import { makeStyles } from '@material-ui/styles'
import { placeholderManImage, toTitleCase } from '../Common Components/CommonFunctions';
import MemberImageDisplayModal from '../Modal/MemberImageDisplayModal';
import { DeleteForeverOutlined, Done, Fastfood, Fireplace } from '@material-ui/icons';
import '../View.css'
import AddCPESubCommitteeModal from '../Modal/AddCPESubcommiteeModal';


const useClasses = makeStyles(theme => ({
  iconContainer: {
      "&:hover $icon": {
          color: 'rgb(97,85,224)',
      }
  },
  icon: {
      color: '#291770',
  },
}))
function CPESubCommittee() {
  const classes = useClasses()

  const [schools, setschools] =useState([]);
  const [loading, setloading] = useState([false]);
  const [modalImage, setModalImage] = useState("");
  const [userType, setUserType] = useState("")
  const [imageModal, setImageModal] = useState(false)
  const {currentUser} = useContext(AuthContext);
  const uid = currentUser.uid
  const [wcModalOpen, setWCModalOpen] = useState(false)
  const [modalOpen, setModalOpen] = useState(false)
  const [users, setUsers] = useState([])
  const [allposts, setAllPosts] = useState({})
  const [availablePosts, setAvailablePosts] = useState([])
  const [wcId, setWCId] = useState([])
  const [selectedWCId,setSelectedWCId ] = useState('')
  const [allWCs, setAllWCs] = useState([])
  const [wcUserArray, setWCUserArray] = useState([])
  const [wcallowed,setwcallowed] = useState('')
  const [displayArray, setDisplayArray] = useState([])

  useEffect(()=>{
    setDisplayArray([])
    if(selectedWCId != ""){
      let temp =[]
      for(let i=0; i< wcUserArray.length; i++){
        if(wcUserArray[i]['committeeid'] == selectedWCId){
          temp.push(wcUserArray[i])
        }
      }
      setDisplayArray(temp)
    }
   
  },[selectedWCId, wcUserArray])

  function sortByProperty(property){  
    return function(a,b){  
       if(a[property] > b[property])  
          return 1;  
       else if(a[property] < b[property])  
          return -1;  
   
       return 0;  
    }  
 }

 function DeletePost(comid,postid){
     if(window.confirm("Are You Sure?\n This can't be undone")){
        const ref = firebase.firestore().collection('cpesubcommittee').doc(comid)
        firebase.firestore().collection('cpesubcommittee').doc(comid).collection('posts').doc(postid).delete().then(()=>{
           ref.update({[`${postid}`]:""}).then(()=>{
               window.alert("Working Commitee Member Removed from Database !!")
               window.location.reload()
           })
        })
     }
   
 }
  async function GetAllWCs(){
    const collectionRef = firebase.firestore().collection('cpesubcommittee')
    let documents = await collectionRef.get();

  
    documents.forEach(async doc => {
     
        let subCollectionDocs = await collectionRef.doc(doc.id).collection("posts").get()
        let temp =[]
        subCollectionDocs.forEach(subCollectionDoc => {
           
            temp.push(subCollectionDoc.data())
           
        });
        setAllWCs(allWCs=>[...allWCs,...temp])
    });
    
   
  }

  function GetWCUserArray(){
      let temp=[]
      for(let i=0; i<allWCs.length; i++){
          for(let j=0; j< schools.length; j++ ){
              if(allWCs[i]['userid'] == schools[j]['userid']){
                  let x = {}
                  Object.assign(x,allWCs[i],schools[j]) 
                  temp.push(x)
                      
              }
          }
      }
    temp.sort(sortByProperty('displayorder'))
    setWCUserArray(temp)
    
  }
useEffect(()=>{
    if(schools.length !=0 && allWCs.length !=0){
        GetWCUserArray()
    }
},[allWCs, schools])

  function GetWCIds(){
      const ref = firebase.firestore().collection('cpesubcommittee').doc('cpeid') 
      ref.get().then((doc)=>{
          let temp =[]
          let ids = Object.keys(doc.data())
          let values = Object.values(doc.data())
            for(let i=0; i<ids.length; i++){
                let x ={}
                x['id'] = ids[i]
                x['value'] = values[i]
                temp.push(x)
            }
            setSelectedWCId(temp[temp.length-1]['id'])
            setWCId(temp)
        
      }) 
  }

  function GetAllWCPosts(){
      const ref = firebase.firestore().collection('cpesubcommittee').doc('allposts')
      ref.get().then((doc)=>{
          setAllPosts(doc.data())
      })
  }

  function GetAllWCPostsforId(){
      
    const ref = firebase.firestore().collection('cpesubcommittee').doc(selectedWCId)
   
    ref.get().then((doc)=>{
    if(doc.exists){
        
        let temp =[]
        let allPostIds = Object.keys(allposts)
        let allPostDisplayName = Object.values(allposts)
         let x = doc.data()
         for(let i=0; i<allPostIds.length; i++){
             if(x[allPostIds[i]] != 'r'){
                  let z ={}
                  z['id']  = allPostIds[i]
                  z['displayname'] = allPostDisplayName[i]
                  temp.push(z)
             }
         }
     setAvailablePosts(temp)
    }
    else{
        let temp=[]
        let allPostIds = Object.keys(allposts)
        let allPostDisplayName = Object.values(allposts)
        for(let i=0; i<allPostIds.length; i++){
          
                 let z ={}
                 z['id']  = allPostIds[i]
                 z['displayname'] = allPostDisplayName[i]
                 temp.push(z)
      
        }
        setAvailablePosts(temp)
      } 
  })
}

useEffect(()=>{
   
    if(allposts != undefined && selectedWCId != ""){
        GetAllWCPostsforId()
    }
},[allposts, selectedWCId])

  useEffect(()=>{
   getSchools()
   GetAllWCPosts()
    GetWCIds()
    GetAllWCs()

  },[])

  const ref1 = firebase.firestore().collection('users').doc(uid)
  useEffect(() => {
    ref1
      .get()
      .then((doc) => {
        if (doc.exists) {
          
         setwcallowed(doc.data().cpesubcommittee)
        
               } else {
          // doc.data() will be undefined in this case
        }
      })
      .catch((error) => {});
     
    
  }, []);
  


 



 function getSchools(){

    let  ref = firebase.firestore().collection("users").orderBy('name');
   setloading(true);
   const items=[];
   let temp =[]
   ref.onSnapshot((querySnapshot)=>{
   
     querySnapshot.forEach((doc)=>{
         
           items.push(doc.data());
           let x ={}
           x['label'] = toTitleCase(doc.data()['name'])
           x['value'] = doc.data()['userid']
           temp.push(x)

     });
     setUsers(temp)
     setschools(items);
     setloading(false)
  

     
   })
 }  
 
 


 function OpenImageModal(imageSrc){
   setModalImage(imageSrc)
   setImageModal(true)
 }
 if(loading){
   return (<LoaderUi/>)
 }







return (
    <Fragment >
      <IdleTimeContainer/>
      <LoginNavbar />
      <Navbar/>
      <SideMenuBar />
      {/* {modalOpen && <Modal setOpenModal={setModalOpen}/>} */}
      {wcModalOpen && (<AddCPESubCommitteeModal setOpenModal={setWCModalOpen} users={users} committeeid={selectedWCId} committeeArray={wcId} currentuserid={uid} availablePosts={availablePosts}/>)}
      {imageModal && (<MemberImageDisplayModal imageSrc={modalImage} setOpenModal={setImageModal}/>)}
      <div className="divmargin">
  
           <div style={{paddingTop:"120px",zIndex:0}}></div>
    <MDBContainer>
      <MDBRow>
        <MDBCol md="6"><h1 style={{color:"#291770"}}>CPE Sub-Committee</h1></MDBCol>
        <MDBCol md="3"></MDBCol>
        <MDBCol md="3"></MDBCol>
      </MDBRow>
    </MDBContainer>
    <MDBContainer  
    >
      <MDBRow>  <MDBCol md="3" >
      </MDBCol>
      
      <MDBCol md="6">  <div className="card  d-flex justify-content-center "
    // style={{
    //   padding: "16px",
    //   margin: "16px",
    //   width: "38rem",
    //   display:"inline"
      
    // }}
      >
      <div className="p-2 col-example">
      <div className="inputBx1">
                 <span>Select Year</span><span style={{color:"red"}}>*</span><br/>
                 <select value={selectedWCId}  onChange={(e)=> setSelectedWCId(e.target.value) }>         
                 <option value="">Choose</option>
                {wcId.map((val, key)=> (<option value={val.id} key={key}>{toTitleCase(val.value)}</option>))}
                   </select>  
               </div>
 </div></div>
      
      </MDBCol>
      {/* <MDBCol md='1'></MDBCol>  */}
      <MDBCol md='3'>
          {/* {userType=="admin"?<MemberPortalDownload />:""} */}
       {wcallowed == 'yes' ?  <button onClick={()=>setWCModalOpen(true)} className='btn btn-primary' style={{background:"#291770", color:"white"}}>Add CPE Sub-Committee</button> :""}
          </MDBCol>
      
      </MDBRow>
     
    </MDBContainer>
  
  
  
    
    {displayArray.length == 0 ? <h1>No Data to Display !!</h1> :""}
      {displayArray.map((school,key)=>(       
 
        <div  key={school.mid} >     
    
        <div  style={{padding:"20px",cursor:"pointer"}} className="d-flex bd-highlight example-parent justify-content-around">
        <div className="p-2  bd-highlight col-example" ></div>

           <div className="p-2  flex-shrink-1 bd-highlight col-example" >
           
          <img src={school.image} onError={(e)=>{e.target.onerror = null; e.target.src=placeholderManImage}} onClick={()=> OpenImageModal(school.image)} width="200px" style={{ width: 150,
              height: 150,
              borderRadius: 100/ 2,
              overflow: "hidden",
              border:"1px solid #291770"
            }} 

            
               />
<div className="MobileView" >
            
            <IconButton href={"mailto:"+  school.email} classes={{ root:classes.iconContainer}} ><EmailIcon style={{fontSize:50}} className={classes.icon} /> </IconButton> 
            <IconButton href={"tel:"+  school.phone}  classes={{ root:classes.iconContainer}} > <PhoneInTalkRoundedIcon style={{fontSize:50}} className={classes.icon} /> </IconButton>
            <IconButton href={"https://www.google.com/maps/dir// "+  school.raddress} classes={{ root:classes.iconContainer}} > <LocationOnRoundedIcon style={{fontSize:50}} className={classes.icon} /> </IconButton>
            <IconButton href={"https://wa.me/"+ "+91"+school.whatsapp}  classes={{ root:classes.iconContainer}} ><WhatsAppIcon style={{fontSize:50}} className={classes.icon}></WhatsAppIcon></IconButton>
         {wcallowed == 'yes'?<>  <br/> <IconButton onClick={()=> DeletePost(school.committeeid, school.postid)} classes={{ root:classes.iconContainer}} ><DeleteForeverOutlined style={{fontSize:50}} className={classes.icon} /></IconButton></> :""}
             
             </div>
</div>

<div className="p-2 flex-fill flex-grow-1 bd-highlight col-example" style={{textAlign:"center", border:"2px solid #6256E0"}} onClick={()=> window.location.href=`/member-directory/${school.mid}`}>
 
         <p style={{color:"black", fontSize:"30px" }} >CA&nbsp;{toTitleCase (school.name)}</p>
         
         <p style={{color:"#291770", fontSize:"30px" }} > {school.mid} </p>
         <p style={{color:"#291770", fontSize:"30px" }} > {school.postname} </p>

 
        
          </div>
          
          <div className="DesktopView" >
            
           <IconButton href={"mailto:"+  school.email} classes={{ root:classes.iconContainer}} ><EmailIcon style={{fontSize:100}} className={classes.icon} /> </IconButton> 
           <IconButton href={"tel:"+  school.phone}  classes={{ root:classes.iconContainer}} > <PhoneInTalkRoundedIcon style={{fontSize:100}} className={classes.icon} /> </IconButton>
           <IconButton href={"https://www.google.com/maps/dir// "+  school.raddress} classes={{ root:classes.iconContainer}} > <LocationOnRoundedIcon style={{fontSize:100}} className={classes.icon} /> </IconButton>
           <IconButton href={"https://wa.me/"+ "+91"+school.whatsapp}  classes={{ root:classes.iconContainer}} ><WhatsAppIcon style={{fontSize:100}} className={classes.icon}></WhatsAppIcon></IconButton>
        {wcallowed == 'yes'?<>  <br/> <IconButton onClick={()=> DeletePost(school.committeeid, school.postid)} classes={{ root:classes.iconContainer}} ><DeleteForeverOutlined style={{fontSize:100}} className={classes.icon} /></IconButton></> :""}
            
            </div>
        </div></div>
        
      
      
            
      ))}
 
 </div>   
   
    </Fragment>   
  );
}

export default CPESubCommittee;
